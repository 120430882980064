import { HiOutlineFolderAdd } from "react-icons/hi";
import "./items.css";
import { useState } from "react";
import { getFirestore, collection, addDoc, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';

function AddSecao({ onClose, estudioID }) {
    const [nomeSecao, setNomeSecao] = useState(""); // Estado para armazenar o nome da seção

    // Manipulador de evento para atualizar o estado quando o usuário digitar no input
    const handleChangeNomeSecao = (e) => {
        const novoNomeSecao = e.target.value;

        // Limitar o nome da seção a 40 caracteres
        if (novoNomeSecao.length <= 40) {
            setNomeSecao(novoNomeSecao);
        }
    };

    // Manipulador de evento para fechar o componente AddSecao
    const handleFechar = () => {
        onClose();
    };

    const handleSalvar = async () => {
        const db = getFirestore();
    
        // Consulta para obter o número de documentos na coleção 'sessoes' com o EstudioID específico
        const secoesQuery = query(collection(db, 'sessoes'), where('EstudioID', '==', estudioID));
        const secoesSnapshot = await getDocs(secoesQuery);
    
        // Determinar a ordem com base no número de documentos encontrados
        const ordem = secoesSnapshot.size;
    
        try {
            // Adicione um novo documento à coleção 'sessoes'
            const docRef = await addDoc(collection(db, 'sessoes'), {
                EstudioID: estudioID,
                nomeSessao: nomeSecao,
                online: false,
                ordem: ordem , // Incrementa a ordem
            });
    
            // Obtém o ID do documento recém-criado e atualiza o campo ID_Sessao
            const ID_Sessao = docRef.id;
            await updateDoc(doc(db, 'sessoes', docRef.id), { ID_Sessao });
    
            // Fecha o componente
            onClose();

      

        } catch (error) {
            console.error("Erro ao criar um novo documento:", error);
        }
    };

    const isButtonDisabled = nomeSecao.trim() === "";

    return (
        <div className="bgAcao" onClick={handleFechar}>
            <div className="cardAddSecao" onClick={(e) => e.stopPropagation()}>
                <div className="nameAction">
                    <HiOutlineFolderAdd className="iconCat" />
                    Adicionar seção
                </div>

                <div className="contCard">
                    <input
                        className="input"
                        type="text"
                        name="novaSecao"
                        autocomplete="off"
                        placeholder="Digite aqui o nome da seção"
                        value={nomeSecao}
                        onChange={handleChangeNomeSecao}
                    />
                    <div className="botoesCard">
                        <button className="brCardCancel" onClick={handleFechar}>
                            Cancelar
                        </button>
                        <button
                            className={`btCardConfirm ${isButtonDisabled ? "disabled-button" : ""}`}
                            disabled={isButtonDisabled}
                            onClick={handleSalvar}
                        >
                            Salvar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddSecao;
