import React, { useState, useEffect } from "react";
import { MdDeleteForever } from "react-icons/md";
import CropEasy from "../../../pages/membros/crop/CropEasy";
import { LuFilePlus } from "react-icons/lu";
import "../Items/items.css";
import Photos from "./Photos";
import Descricao from "./Descricao";
import Tamanho from "./Tamanho";
import Preco from "./Preco";
import { collection, addDoc, query, where, getDocs } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db } from "../../../services/firebaseConfig";
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import ProgressoSave from "./saveProgress";

function AddTattoo({ onClose, eID, sID }) {
  const [imageData, setImageData] = useState({});
  const [nomeTattoo, setNomeTattoo] = useState("");
  const [descricao, setDescricao] = useState("");
  const [tamanho, setTamanho] = useState(0);
  const [preco, setPreco] = useState(0);
  const [stateTamanho, setStateTamanho] = useState(false);
  const [stateDescricao, setStateDescricao] = useState(false);
  const [statePreco, setStatePreco] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [isPhE1Visible, setIsPhE1Visible] = useState(false);
  const [progress, setProgress] = useState(0);

  

  const handleCroppedImage = (inputId, croppedImage) => {
    setImageData({ ...imageData, [inputId]: croppedImage });
  };

  const uploadImageAndGetURL = async (imageDataKey) => {
    if (!imageData[imageDataKey]) return "";

    const storage = getStorage();
    const storageRef = ref(storage, `tattoos/${eID}/${sID}/${Date.now()}.jpeg`);

    const response = await fetch(imageData[imageDataKey]);
    const blob = await response.blob();

    await uploadBytes(storageRef, blob);
    return await getDownloadURL(storageRef);
  };


  
  

  const handleSave = async () => {
    setLoading(true);
    try {
      setProgress(10);

      const img1URL = await uploadImageAndGetURL("ph1");
      setProgress(25);
      const img2URL = await uploadImageAndGetURL("ph2");
      setProgress(40);
      const img3URL = await uploadImageAndGetURL("ph3");
      setProgress(55);
      const img4URL = await uploadImageAndGetURL("ph4");
      setProgress(70);

      const q = query(collection(db, "tattoos"), where("SecaoID", "==", sID));
      const querySnapshot = await getDocs(q);
      const ordem = querySnapshot.size;

      await addDoc(collection(db, "tattoos"), {
        img1: img1URL,
        img2: img2URL,
        img3: img3URL,
        img4: img4URL,
        nomeTattoo: nomeTattoo,
        StateDescricao: document.querySelector(".toggleOND").style.display === "flex",
        StateTamanho: document.querySelector(".toggleONT").style.display === "flex",
        StatePreco: document.querySelector(".toggleONP").style.display === "flex",
        descricao: descricao,
        preco: preco,
        tamanho: tamanho,
        top: "",
        EstudioID: eID,
        SecaoID: sID,
        ordem: ordem
      });
      setProgress(100);

      setTimeout(() => {
        onClose();
    }, 1000);
      
      onClose();
    } catch (error) {
      console.error("Erro ao adicionar o documento: ", error);
    } finally {
      setLoading(false);
      setProgress(0);
    }
  };

  const handleNomeTattooChange = (e) => {
    let value = e.target.value;
  
    // Garante que o primeiro caractere seja maiúsculo
    value = value.charAt(0).toUpperCase() + value.slice(1);
  
    // Limita o comprimento a 40 caracteres
    if (value.length > 30) {
      value = value.slice(0, 30);
    }
  
    setNomeTattoo(value);
  };
  
  
  
  const handleImageRemove = (imageDataKey) => {
    setImageData({ ...imageData, [imageDataKey]: null });
  };
  

  
  return (
    <div className="bgAcao">
      
      <div className="cardAddSecao">
        <div className="nameAction">
        <LuFilePlus className="icon"/>
          Adicionar Tattoo
        </div>

        <div className="contCard">
          <Photos handleCroppedImage={handleCroppedImage} handleImageRemove={handleImageRemove}  />

          <div style={{ display: "block", textAlign: "left" }}>
            Nome da Tattoo:*
            <input
              id="nomeTattoo"
              className="input"
              type="text"
              name="nomeTatto"
              autocomplete="off"
              value={nomeTattoo}
              placeholder="Digite aqui o nome da tattoo"
              onChange={handleNomeTattooChange}
            />
          </div>

          <Descricao setDescricao={setDescricao} setStateDescricao={setStateDescricao} />

          <div className="TamPreco">
            <Tamanho setTamanho={setTamanho} setStateTamanho={setStateTamanho} />
            <Preco updatePreco={setPreco} setStatePreco={setStatePreco} />
          </div>

          <div className="botoesCard">
            <button id="cancel" className="brCardCancel" onClick={onClose}>
              Cancelar
            </button>
            <button id="saveT" className="btCardConfirm" onClick={handleSave} disabled={!nomeTattoo || !imageData['ph1']}>
              Salvar
            </button>
          </div>
        </div>
      </div>

      <Dialog open={loading}>
      <ProgressoSave progress={progress} />
      </Dialog>
    </div>
  );
}

export default AddTattoo;


