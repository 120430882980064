import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { onSnapshot, query, where, collection, doc, updateDoc } from "firebase/firestore";
import { db } from '../../services/firebaseConfig';
import "./bio.css";
import "./bts.css"
import { BiChevronsRight, BiLogoWhatsapp } from "react-icons/bi";
import { PiUserListBold } from "react-icons/pi";
import Carrossel from './Carrossel';
import Moldura from './Moldura';
import Catalogo from './Catalogo';
import TattooDetails from './TattooDetails';
import Sobre from './Sobre';
import { Helmet } from 'react-helmet';

function Bio() {
  const { estudioId } = useParams();
  const [estudioData, setEstudioData] = useState(null);
  const [estudioNaoEncontrado, setEstudioNaoEncontrado] = useState(false);
  const [mostrarCatalogo, setMostrarCatalogo] = useState(false);
  const [mostrarSobre, setMostrarSobre] = useState(false);
  const [estudioIdDoc, setEstudioIdDoc] = useState(null);
  const [opacity, setOpacity] = useState(1);



  const temaParaClasse = {
    vermelho:'pgBranco', laranja:'pgBranco', amarelo:'pgBranco', verde:'pgBranco', ciano:'pgBranco',
    azul:'pgBranco', roxo:'pgBranco', rosa:'pgBranco', magenta:'pgBranco', branco:'pgBranco',

    goldVermelho: 'pgVermelho', goldVerde:'pgVerde', goldAzul:'pgAzul', goldRoxo:'pgRoxo', goldAmarelo:'pgAmarelo',
    silverVermelho: 'pgVermelho', silverVerde:'pgVerde', silverAzul:'pgAzul', silverRoxo:'pgRoxo', silverAmarelo:'pgAmarelo',
    vermelhoVerde:'pgVerde', laranjaCiano:'pgCiano', verdeVermelho:'pgVermelho', azulAmarelo:'pgAmarelo',

  };

  const botao = {
    vermelho: 'btVermelho', laranja: 'btLaranja', amarelo: 'btAmarelo', verde: 'btVerde', ciano: 'btCiano',
    azul:'btAzul', roxo:'btRoxo', rosa:'btRosa', magenta:'btMagenta', branco: 'btBranco',

    goldVermelho: 'btAmarelo', goldVerde:'btAmarelo', goldAzul:'btAmarelo', goldRoxo:'btAmarelo', goldAmarelo:'btAmarelo',

    silverVermelho: 'btBranco', silverVerde:'btBranco', silverAzul:'btBranco', silverRoxo:'btBranco', silverAmarelo:'btBranco',

    vermelhoVerde:'btVermelho', laranjaCiano:'btLaranja', verdeVermelho:'btVerde', azulAmarelo:'btAzul',
}

const icBotao = {
  vermelho: 'biVermelho', laranja: 'biLaranja', amarelo: 'biAmarelo', verde: 'biVerde', ciano: 'biCiano',
  azul:'biAzul', roxo:'biRoxo', rosa:'biRosa', magenta:'biMagenta', branco: 'biBranco',

  goldVermelho: 'biAmarelo', goldVerde:'biAmarelo', goldAzul:'biAmarelo', goldRoxo:'biAmarelo', goldAmarelo:'biAmarelo',

  silverVermelho: 'biBranco', silverVerde:'biBranco', silverAzul:'biBranco', silverRoxo:'biBranco', silverAmarelo:'biBranco',

  vermelhoVerde:'biVermelho', laranjaCiano:'biLaranja', verdeVermelho:'biVerde', azulAmarelo:'biAzul',
}

const text = {
  vermelho: 'txVermelho', laranja: 'txLaranja', amarelo: 'txAmarelo', verde: 'txVerde', ciano: 'txCiano',
  azul:'txAzul', roxo:'txRoxo', rosa:'txRosa', magenta:'txMagenta', branco: 'txBranco',

  goldVermelho: 'txAmarelo', goldVerde:'txAmarelo', goldAzul:'txAmarelo', goldRoxo:'txAmarelo', goldAmarelo:'txAmarelo',

  silverVermelho: 'txBranco', silverVerde:'txBranco', silverAzul:'txBranco', silverRoxo:'txBranco', silverAmarelo:'txBranco',

  vermelhoVerde:'txVermelho', laranjaCiano:'txLaranja', verdeVermelho:'txVerde', azulAmarelo:'txAzul',
}

  const fontPage = {
    roboto: 'fontRoboto', slab: 'fontSlab', serif: 'fontSerif'
  }


  const exibirCatalogo = () => {
    setMostrarCatalogo(true);
    const dataAtual = new Date();
    const dataFormatada = `${dataAtual.getDate()}/${dataAtual.getMonth() + 1}/${dataAtual.getFullYear()} ${dataAtual.getHours()}:${dataAtual.getMinutes()}`;
  
    // Atualiza o estudioData.lastView com a data e hora atual
    const updatedEstudioData = {
      ...estudioData,
      lastView: dataFormatada,
    };
  
    // Atualiza o documento no Firebase
    updateDoc(doc(db, 'estudio', estudioIdDoc), updatedEstudioData);
  
    
  };
  

  const abrirWhatsApp = (numero) => {
    window.open(`https://wa.me/${numero}`, '_blank');
  };

 

  useEffect(() => {
    // Inicia a busca após um atraso de 2000 milissegundos (2 segundos)
    const timeoutId = setTimeout(() => {
      const q = query(collection(db, 'estudio'),
        where('nickname', '==', estudioId)
      );
  
      // Escutar atualizações em tempo real
      const unsubscribe = onSnapshot(
        q, (querySnapshot) => {
          if (querySnapshot.size === 0) {
            setEstudioNaoEncontrado(true);
          } else {
            querySnapshot.forEach((document) => {
              setEstudioData(document.data());
              setEstudioIdDoc(document.id);

              
            });
          }
        }
      );
  
      // Parar de escutar quando o componente for desmontado
      return () => {
        unsubscribe();
      };
    }, 500);  // 2000 milissegundos = 2 segundos
  
    // Limpa o timeout se o componente for desmontado antes de 2 segundos
    return () => {
      clearTimeout(timeoutId);
    };
  }, [estudioId]);
  

    const handleBorderClick = () => {
      setTransitions(true);
      setTimeout(() => {
          exibirCatalogo();
      }, 0);
  };

  const [transitions, setTransitions] = useState(false);

  return (
    <div id='pg' >
    {estudioNaoEncontrado ? (<div className='dont'>Estudio não encontrado</div>) 
    : estudioData ? ( 
      estudioData.statusConta ? (
        <div>

        <Helmet>
        <title>{estudioData.nomeEstudio}</title>
          <meta property="og:title" content={estudioData.nomeEstudio} />
          <meta property="og:description" content={estudioData.nomeEstudio} />
          <meta property="og:image" content={estudioData.perfil} />
          
        </Helmet>

          <div className={`transitionBefore ${estudioData ? temaParaClasse[estudioData.tema] || '' : ''} ${estudioData ? fontPage[estudioData.fonte] || '' : ''}`}  style={{ display: transitions ? 'none' : 'flex' }}/>
          <div id="pg" className={`${estudioData ? temaParaClasse[estudioData.tema] || '' : ''} ${estudioData ? fontPage[estudioData.fonte] || '' : ''}`} style={{ display: mostrarCatalogo ? 'none' : 'flex' }}>
              
            <Moldura cor={estudioData.tema} estilo={estudioData.estilo}/>
             
            <div className="p1">
    
                <Carrossel ftP={estudioData.perfil}
                  p1={estudioData.imgBio1} p2={estudioData.imgBio2}
                  p3={estudioData.imgBio3} p4={estudioData.imgBio4}
                  p5={estudioData.imgBio5} p6={estudioData.imgBio6}
                  p7={estudioData.imgBio7} tema={estudioData.tema} estilo={estudioData.estilo}/>  

              </div> 

                {/* Nome e botões */}
              <div className='p2'>
                <div className='nome'>{estudioData.nomeEstudio}</div>
        
                {/* Botão Catálogo */}
                <div className={`btCat ${estudioData ? botao[estudioData.tema] || '' : ''}`} onClick={handleBorderClick}>
                    <p className='cta' >Conhecer o Catálogo</p>
                    <div className={`iconBT ${estudioData ? icBotao[estudioData.tema] || '' : ''}`} onClick={handleBorderClick}></div>
                    <BiChevronsRight className='iconC iAmarelo1'/> 
                </div>

                {/* Botão WhatsApp */}
                <div className='btWhats1' 
                 style={{display: estudioData && estudioData.whatsapp ? 'flex' : 'none'}}
                  onClick={() => estudioData && estudioData.whatsapp && abrirWhatsApp(estudioData.whatsapp)}>
                  <div className='txtCta'>
                        <div className='cWhats'>Fazer orçamento</div>
                        <div className='ctaSub'>ou tirar dúvidas</div>
                  </div>

                  <div className='iconBTW1'></div>
                    <BiLogoWhatsapp className='iconW1'/> 
                </div>

                {/* Saiba mais */}
               {estudioData.sobre && <div className='btSaiba1' onClick={()=>setMostrarSobre(true)}>
                  <div className='txtCta'>
                    <div className='ctaSub'>Saber mais de</div>
                    <div className={`cta tx ${estudioData ? text[estudioData.tema] || '' : ''}`}>{estudioData.nomeEstudio}</div>
                  </div>

                  <div className='iconBTS1'></div>
                  <PiUserListBold className='iconS1'/> 
                </div>  }  

                {/*<img src='https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/Group%20272.svg?alt=media&token=c2adaba8-ea60-4a5d-8089-9775f6e8284f'
                alt='' style={{height: "20px", position:"absolute", bottom:"8px"}}/>*/}
              </div> 
             
          </div>
          
              
            
             
          {mostrarCatalogo && <Catalogo tema={estudioData.tema} estilo={estudioData.estilo} fonte={estudioData.fonte} eID={estudioIdDoc}
           nome={estudioData.nomeEstudio} perfil={estudioData.perfil} topON={estudioData.topOn} numero={estudioData.whatsapp} setMostrarCatalogo={()=>setMostrarCatalogo(false)}/>} 
             
          {mostrarSobre && <Sobre nome={estudioData.nomeEstudio} tema={estudioData.tema} estilo={estudioData.estilo}  capa={estudioData.capaSobre} descricao={estudioData.descricao} setMostrarSobre={()=>setMostrarSobre(false)} />}
          
        </div>):(<div className='dont'> A página do {estudioData.nomeEstudio} está indisponível</div>)
      ) : (
        <div className='dont'>
          <img src='https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2FKtalusBlack.svg?alt=media&token=17878113-58be-4e5f-9f0d-0f75aaf7ec44&_gl=1*1rb2c3q*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NzczMTAyOS4yNjcuMS4xNjk3NzMxMDYwLjI5LjAuMA..'
                alt='' style={{height: "80px", position:"absolute", bottom:"8px"}}/>
          
          </div>
      )}
    </div>
  );
}

export default Bio;
