import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "../pages/login/LoginPage";
import Register from "../pages/register/register";
import Home from "../pages/membros/AMembros";
import Home2 from "../pages/membros/AMembros2";
import Img from "../pages/membros/Img";
import Bio from "../pages/bio/bio";
import Pos from "../pages/register/PosRegister";
import Personalizacao from "../pages/register/Personalizacao";
import NewEstudio from "../pages/register/NewEstudio";
import Convites from "../pages/convites/convites";
import Termo from "../components/C_Body/Termos e Politica/termo";
import Politica from "../components/C_Body/Termos e Politica/politica";
import RegisterLista from "../pages/register/register copy";
import Register2 from "../pages/register/register copy 2";
import SDIred from "../pages/login/sdired";
import SdiLp from "../pages/login/SdiLp";
import SdiLpO from "../pages/login/SdiLpO";



export function AppRoutes(){

    return (
     <BrowserRouter>
        <Routes>
            <Route path="/loginxx" element={<Login/>} />
            <Route path="/kregxx" element={<Register/>} />
            <Route path="/kreg007" element={<Register2/>} />
            <Route path="/home" element={<Home/> } />
            
            <Route path="/img" element={<Img/> } />
            <Route path="/pos" element={<NewEstudio/> } />
            
            <Route path="/:estudioId" Component={Bio} />
            <Route path="/x1xxx" element={<Convites/> } />
            <Route path="/kter" element={<Termo/> } />
            <Route path="/kpol" element={<Politica/> } />
            <Route path="/klist" element={<RegisterLista/> } />
            <Route path="/ksdi" element={<SDIred/> } />
            <Route path="/sdi" element={<SdiLp/> } />
            <Route path="/sdio" element={<SdiLpO/> } />
            
        </Routes>
     </BrowserRouter>
    )
}