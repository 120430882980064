import { BsLink45Deg, BsQrCode, BsInfinity, BsFillKeyFill } from "react-icons/bs";
import { HiColorSwatch } from "react-icons/hi";
import { FaRankingStar } from "react-icons/fa6";
import { BiSupport } from "react-icons/bi";


function AssOfertaMensal({setComponenteAtivo, plano, email}){
    function abrirLink() {
        window.open('https://pay.kiwify.com.br/XNG6APi', '_blank');
      }

    return(
        <div className="ass-modoMensal" >
        <div className="ass--modo">
            <div style={{fontWeight:700, fontSize:'24px'}}>Mensal</div>

            <div className="toggleM" onClick={() => setComponenteAtivo("anual")}>
                <div className="toggleM--ballM"></div>
            </div>

            <div onClick={() => setComponenteAtivo("anual")} style={{display:'flex', flexDirection:'row', alignItems:'center', gap:'4px', color:'#FEEC6E'}}>Anual<span style={{fontSize:'12px', color:'#94FF94'}}>-29%</span></div>
        
        </div>

        <div className="ass--itens">
                <div className="ass--item"><BsLink45Deg style={{marginLeft:'64px', fontSize:'20px', color:'#FEEC6E'}}/>Link exclusivo</div>
                <div className="ass--item"><BsQrCode style={{marginLeft:'64px', fontSize:'20px', color:'#FEEC6E'}}/>QR-Code</div>
                <div className="ass--item"><HiColorSwatch style={{marginLeft:'64px', fontSize:'20px', color:'#FEEC6E'}}/>Catálogo personalizado</div>
                <div className="ass--item"><FaRankingStar style={{marginLeft:'64px', fontSize:'20px', color:'#FEEC6E'}}/>Seção TOP7</div>
                <div className="ass--item"><BsInfinity style={{marginLeft:'64px', fontSize:'20px', color:'#FEEC6E'}}/>Tattos ilimitadas</div>
                <div className="ass--item"><BiSupport style={{marginLeft:'64px', fontSize:'20px', color:'#FEEC6E'}}/>Suporte exclusivo</div>
                <div className="ass--item" style={{fontWeight:700}}><BsFillKeyFill style={{marginLeft:'64px', fontSize:'20px', color:'#FEEC6E'}}/>Acesso por 30 dias</div>

        </div>

        <div style={{display:'flex', flexDirection:'row', marginLeft:'24px'}}>De<span style={{marginLeft:'4px', textDecoration:'line-through', fontWeight:700, color:'#FEEC6E'}}>R$99,90</span> </div>

        <div className="ass--preco">
            <div style={{fontSize:'29px'}}>R$</div> 
            <div style={{fontSize:'124px', lineHeight:0.85, fontWeight:700, position:'relative' }}>79
            <div className="ass--anual" style={{textAlign:'left'}} >no<br/>1ºmês</div></div> 
            <div style={{fontSize:'29px'}}>,90*</div>   
        </div>

        <div style={{width:'100%', display:'flex', justifyContent:'center'}}>no Pix ou Cartão de Crédito</div>
        <div style={{width:'100%', display:'flex', justifyContent:'center', fontSize:'10px', marginTop:'8px', color:'rgba(255,255,255,0.5)'}}>*A partir do 2º mês, o valor mensal volta a ser R$99,90</div>

        {plano !== 'monthly' ? (<div className="ass--botaoCTA" onClick={abrirLink}>Quero o Plano Mensal</div>)
        :(<div className="ass--botaoPatual">Seu Plano Atual</div>)}
        <div style={{fontSize:'12px', color:'#94FF94'}}> Compra segura pela Kiwify</div>

    </div>
    )
    
}


export default AssOfertaMensal;