
import React, { useRef, useEffect } from 'react';
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";




function Slide ({img1, img2, img3, img4, stT, stP, tam, preco}){
    const scrollContainerRef = useRef(null);
    const [isSlide2Visible, setIsSlide2Visible] = React.useState(true);
    const [isSlide3Visible, setIsSlide3Visible] = React.useState(true);
    const [isSlide4Visible, setIsSlide4Visible] = React.useState(true);

    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    useEffect(() => {
      // Pegar as divs pela sua classe
      const leftButtons = document.querySelectorAll(".slide--btL");
      const rightButtons = document.querySelectorAll(".slide--btR");
      const plusButtons = document.querySelectorAll(".slide--btPlus");
  
      leftButtons.forEach((left) => {
        if (isMobile) {
          // Adicionar a classe 'mobile' à div
          left.classList.add('mobile');
        } else {
          // Adicionar a classe 'desktop' à div
          left.classList.add('desktop');
        }
      }); 
  
      rightButtons.forEach((right) => {
        if (isMobile) {
          // Adicionar a classe 'mobile' à div
          right.classList.add('mobile');
        } else {
          // Adicionar a classe 'desktop' à div
          right.classList.add('desktop');
        }
      });
    
      plusButtons.forEach((plus) => {
        if (isMobile) {
          // Adicionar a classe 'mobile' à div
          plus.classList.add('desktop');
        } else {
          // Adicionar a classe 'desktop' à div
          plus.classList.add('mobile');
        }
      });
    
    
    }, []);



    const scrollBy = (amount) => {
      const scrollContainer = scrollContainerRef.current;
      const currentScrollX = scrollContainer.scrollLeft;

      console.log('Scroll antes:', currentScrollX);
      scrollContainer.scrollBy({
        left: amount,
        behavior: 'smooth'
      });
    
    setTimeout(() => {
        console.log('Scroll depois:', scrollContainer.scrollLeft);
    }, 300);
      
      void scrollContainer.offsetHeight;
    };
    
    const handleScrollClick = (direction) => {
      const scrollContainer = scrollContainerRef.current;
      const amount = direction === 'right' ? 286 : -286;
      scrollContainer.scrollLeft += amount;
  };

      useEffect(() => {
        const checkVisibility = (id) => {
            const slide = document.getElementById(id);
            if (!slide) return false; // Retorne falso se o elemento não existir
            const style = window.getComputedStyle(slide);
            return style.display !== 'none';
          };
      
        setIsSlide2Visible(checkVisibility('slide2'));
        setIsSlide3Visible(checkVisibility('slide3'));
        setIsSlide4Visible(checkVisibility('slide4'));
      
        const observer = new MutationObserver(() => {
          setIsSlide2Visible(checkVisibility('slide2'));
          setIsSlide3Visible(checkVisibility('slide3'));
          setIsSlide4Visible(checkVisibility('slide4'));
        });
      
        observer.observe(document, { attributes: true, attributeFilter: ['style'], subtree: true });
      
        return () => {
          observer.disconnect();
        };
      }, []);  

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
      
        let startTouchX = 0;
        let currentScrollX = 0;
      
        const handleTouchStart = (e) => {
          startTouchX = e.touches[0].clientX;
          currentScrollX = scrollContainer.scrollLeft;
        };
      
        const handleTouchMove = (e) => {
          const deltaX = startTouchX - e.touches[0].clientX;
          const newScrollX = currentScrollX + deltaX;
          scrollContainer.scrollLeft = newScrollX;
        };
      
        const handleTouchEnd = (e) => {
          const deltaX = startTouchX - e.changedTouches[0].clientX;
          if (deltaX > 0) {
            // arrastou para a esquerda, mova 286px para a direita
            scrollContainer.scrollLeft = currentScrollX + 286;
          } else {
            // arrastou para a direita, mova 286px para a esquerda
            scrollContainer.scrollLeft = currentScrollX - 286;
          }
        };
      
        scrollContainer.addEventListener('touchstart', handleTouchStart);
        scrollContainer.addEventListener('touchmove', handleTouchMove);
        scrollContainer.addEventListener('touchend', handleTouchEnd);
      
        return () => {
          scrollContainer.removeEventListener('touchstart', handleTouchStart);
          scrollContainer.removeEventListener('touchmove', handleTouchMove);
          scrollContainer.removeEventListener('touchend', handleTouchEnd);
        };
      }, []);
      
   
      return (
        <div className="tDet--slides1" ref={scrollContainerRef}>
          <div className='tDet--tamanho' style={{ display: stT ? 'flex' : 'none' }}>{tam} cm</div>
          <div className='tDet--preco' style={{ display: stP ? 'flex' : 'none' }}>R${preco}</div>
          <div id="slide1" className="slideIn">
            {isSlide2Visible && <div className="slide--btR" onClick={() => handleScrollClick('right')}><BsChevronRight /></div>}
            <img className="imgSlide" src={img1} alt="" />
            {img2 && <div className="slide--btPlus" ><BsChevronLeft /></div>}
          </div>
    
          {/* Verifica se img2 é vazio */}
          {img2 && (
            <div id="slide2" className="slideIn">
              <div className='tDet--tamanho' style={{ display: stT ? 'flex' : 'none' }}>{tam} cm</div>
          <div className='tDet--preco' style={{ display: stP ? 'flex' : 'none' }}>R${preco}</div>
              <div className="slide--btL" onClick={() => handleScrollClick('left')}><BsChevronLeft /> </div>
              {isSlide3Visible && <div className="slide--btR" onClick={() => handleScrollClick('right')}><BsChevronRight /></div>}
              <img className="imgSlide" src={img2} alt="" />
              {img3 && <div className="slide--btPlus" ><BsChevronLeft /></div>}

            </div>
          )}
    
          {/* Verifica se img3 é vazio */}
          {img3 && (
            <div id="slide3" className="slideIn">
              <div className='tDet--tamanho' style={{ display: stT ? 'flex' : 'none' }}>{tam} cm</div>
          <div className='tDet--preco' style={{ display: stP ? 'flex' : 'none' }}>R${preco}</div>
              <div className="slide--btL" onClick={() => handleScrollClick('left')}><BsChevronLeft /> </div>
              {isSlide4Visible && <div className="slide--btR" onClick={() => handleScrollClick('right')}><BsChevronRight /></div>}
              <img className="imgSlide" src={img3} alt="" />
              {img4 && <div className="slide--btPlus" ><BsChevronLeft /></div>}
            </div>
          )}
    
          {/* Verifica se img4 é vazio */}
          {img4 && (
            <div id="slide4" className="slideIn">
              <div className='tDet--tamanho' style={{ display: stT ? 'flex' : 'none' }}>{tam} cm</div>
          <div className='tDet--preco' style={{ display: stP ? 'flex' : 'none' }}>R${preco}</div>
              <div className="slide--btL" onClick={() => handleScrollClick('left')}><BsChevronLeft /> </div>
              <img className="imgSlide" src={img4} alt="" />
              
            </div>
          )}
        </div>
      );
    }
    
    export default Slide;