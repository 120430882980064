import { useState, useEffect } from 'react';
import {  AiOutlineClose } from "react-icons/ai";
import { getImageURL } from '../register/GlifosImg'; 


function Sobre({nome, tema, estilo, capa, descricao, setMostrarSobre }){

    const temaS = {
        vermelho: 'pgBranco bordVermelho', laranja: 'pgBranco bordLaranja', amarelo: 'pgBranco bordAmarelo', verde: 'pgBranco bordVerde', ciano: 'pgBranco bordCiano',
        azul:'pgBranco bordAzul', roxo:'pgBranco bordRoxo', rosa:'pgBranco bordRosa', magenta:'pgBranco bordMagenta', branco: 'pgBranco bordBranco',

        goldVermelho: 'pgVermelho bordAmarelo', goldVerde:'pgVerde bordAmarelo', goldAzul:'pgAzul bordAmarelo', goldRoxo:'pgRoxo bordAmarelo', goldAmarelo:'pgAmarelo bordAmarelo',

        silverVermelho: 'pgVermelho bordBranco', silverVerde:'pgVerde bordBranco', silverAzul:'pgAzul bordBranco', silverRoxo:'pgRoxo bordBranco', silverAmarelo:'pgAmarelo bordBranco',
    
        vermelhoVerde:'pgVerde bordVermelho', laranjaCiano:'pgCiano bordLaranja', verdeVermelho:'pgVermelho bordVerde', azulAmarelo:'pgAzul bordAmarelo',
    };

    useEffect(() => {
        // Adiciona um novo estado no histórico do navegador
        // Isso faz com que o botão voltar possa ser ativado
        window.history.pushState(null, null, window.location.pathname);
    
        const handlePopState = (event) => {
          event.preventDefault(); // previne o comportamento padrão de voltar
          setMostrarSobre(); // chama a função handleClick
          //window.history.pushState(null, null, window.location.pathname); // reinsere o estado
        };
    
        // Adiciona o evento popstate ao window
        window.addEventListener('popstate', handlePopState);
    
        // Remove o evento ao desmontar o componente
        return () => {
          window.removeEventListener('popstate', handlePopState);
        };
    
      }, []);

      const textoFormatado = descricao ? descricao.split('\n').map((str, index, array) => 
      index === array.length - 1 ? str : <>
          {str}
          <br />
      </>
  ) : '';



    return(
        <div className="bgSobre">
            <div className={`cardSobre ${tema ? temaS[tema] || '' : ''}`}>
                <img className='AssCantoA'
                        src={getImageURL(estilo, tema)}
                        alt=''/>
                <img className='AssCantoD'
                        src={getImageURL(estilo, tema)}
                        alt=''/>
                <div className="closePlan" onClick={setMostrarSobre}><AiOutlineClose/></div>
                <div className="sobre--conteudo">
                    <div className="sobre--title">{nome}</div>

                    <div className="sobre--capa">
                        <img className="capa--img" src={capa} alt=""/>
                        
                    </div>

                    <div className="sobre--texto">
                   {textoFormatado}
                    </div>

                </div>



            </div>


        </div>
    )
}


export default Sobre;