import React, { useState, useEffect } from 'react';
import { GiAlliedStar } from "react-icons/gi";
import { AiFillCloseSquare } from "react-icons/ai";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../services/firebaseConfig";
import DefinirTop from './Items/DefinirTop';

function ConteudoTop({ eID, stateTop1, stateTop2, stateTop3, stateTop4, stateTop5, stateTop6, stateTop7 }) {
  const [showDefinirTop, setShowDefinirTop] = useState(false);
  const [selectedTopNumber, setSelectedTopNumber] = useState(null);


  const stateTops = [stateTop1, stateTop2, stateTop3, stateTop4, stateTop5, stateTop6, stateTop7];

  // Função para resetar o valor de um "TOP"
  const resetTop = async (topNumber) => {
    const specificTop = stateTops[topNumber - 1];
  console.log('Specific Top:', specificTop);  // Veja o que esse log retorna
  const docId = specificTop?.id;
    console.log('Doc ID:', docId);
    if (docId) {
      const tattooDocRef = doc(db, 'tattoos', docId);
      updateDoc(tattooDocRef, { top: "" })
  .then(() => {
    console.log("Documento atualizado com sucesso");
  })
  .catch((error) => {
    console.log("Erro ao atualizar o documento: ", error);
  });
    }
  };

  // Função para abrir a janela de definição de "TOP"
  const openDefinirTop = (topNumber) => {
    setSelectedTopNumber(topNumber);
    setShowDefinirTop(true);
  };

  // Função para renderizar um "TOP"
  const renderTop = (topNumber) => {
    const topData = stateTops[topNumber - 1];
    const imgSrc = topData?.img1;
    const showSet = !!imgSrc;
    const showEmpty = !imgSrc;
  
    return (
      <>
        <div id={`topE${topNumber}`} className="cardTopEmpty" style={{ display: showEmpty ? 'flex' : 'none' }} onClick={() => openDefinirTop(topNumber)}>
          <p style={{width:'100%', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', textAlign:'center', color:'#FEEC6E'}}><GiAlliedStar className="icon" style={{ fontSize: "32px" }} />Definir TOP {topNumber}</p>
        </div>
        <div id={`topS${topNumber}`} className="cardTopSet" style={{ display: showSet ? 'flex' : 'none' }}>
        <img className='capaTop1' src={imgSrc || ''} alt=""  />
          <div className="tTop">TOP {topNumber}</div>
          <div className="resetTop" onClick={() => resetTop(topNumber)}><AiFillCloseSquare className="icon" style={{background:"rgba(0,0,0,0.5)"}} /></div>
        </div>
      </>
    );
  };

  return (
    <div className="top">
      
      {renderTop(1)}
      {renderTop(2)}
      {renderTop(3)}
      {renderTop(4)}
      {renderTop(5)}
      {renderTop(6)}
      {renderTop(7)}

      {showDefinirTop && <DefinirTop eID={eID} topNumber={selectedTopNumber} onClose={() => setShowDefinirTop(false)} />}
    </div>
  );
}

export default ConteudoTop;
