import React, { useState, useRef, useEffect } from 'react';
import { BiSolidImageAdd } from 'react-icons/bi';
import { BsTrash } from 'react-icons/bs';
import { getFirestore, addDoc, collection, setDoc, query, where, getDocs } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getImageURL } from './GlifosImg'; 
import { useNavigate } from "react-router-dom";
import ProgressoSavePos from '../../components/C_Body/Items/saveProgressPos';
import EnviarMensagemAPI from './whats';
import emailjs from 'emailjs-com';


function Perso2({ onNext, onBack, formData, setFormData, initialCroppedPhotoURL, email }) {

  
  
  const fontPage = {
    roboto: 'fontRoboto', slab: 'fontSlab', serif: 'fontSerif',
  };

  const colorPage = {
    vermelho:'pagePadrao bVer', laranja:'pagePadrao bLan', amarelo:'pagePadrao bAma', verde:'pagePadrao bVerd', ciano:'pagePadrao bCia',
    azul:'pagePadrao bAzu', roxo:'pagePadrao bRox', rosa:'pagePadrao bRos', magenta:'pagePadrao bMag', branco:'pagePadrao bBra',

    goldVermelho: 'pageVer bAma', goldVerde:'pageVerd bAma', goldAzul:'pageAzu bAma', goldRoxo:'pageRox bAma', goldAmarelo:'pageAma bAma',

    silverVermelho: 'pageVer bBra', silverVerde:'pageVerd bBra', silverAzul:'pageAzu bBra', silverRoxo:'pageRox bBra', silverAmarelo:'pageAma bBra',

    vermelhoVerde:'pageVerd bVer', laranjaCiano:'pageCia bLan', verdeVermelho:'pageVer bVerd', azulAmarelo:'pageAma bAzu',
    
    
  };

  const colorBorder = {
    vermelho:'bVer', laranja:'bLan', amarelo:'bAma', verde:'bVerd', ciano:'bCia',
    azul:'bAzu', roxo:'bRox', rosa:'bRos', magenta:'bMag', branco:'bBra',

    goldVermelho: 'bAma', goldVerde:'bAma', goldAzul:'bAma', goldRoxo:'bAma', goldAmarelo:'bAma',

    silverVermelho: 'bBra', silverVerde:'bBra', silverAzul:'bBra', silverRoxo:'bBra', silverAmarelo:'bBra',

    vermelhoVerde:'bVer', laranjaCiano:'bLan', verdeVermelho:'bVerd', azulAmarelo:'bAzu',
    
    
  };
  const navigate = useNavigate();
  const [showProgress, setShowProgress] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleEstiloSelect = (style) => {
    setFormData({ ...formData, estilo: style });
  };


  const handleSave = async () => {

    setShowProgress(true); 
    setProgress(10);
    const db = getFirestore();
    const storage = getStorage();
    const auth = getAuth();
  

    // Obter usuário atual
    const user = auth.currentUser;

    // Carregar a imagem para o Firebase Storage
    const storageRef = ref(storage, `estudios/${user.uid}.jpg`);
    const imageBlob = await fetch(initialCroppedPhotoURL).then(r => r.blob());
    
    const uploadTask = uploadBytesResumable(storageRef, imageBlob);

    uploadTask.on('state_changed', 
      (snapshot) => {
        // Monitorar o progresso do upload
      }, 
      (error) => {
        // Tratar erros
      }, 
      () => {
        // Upload completo, obter a URL de download
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          // Obtém a data e hora atuais
          const getDataAtual = () => {
            const data = new Date();
            return data.toISOString(); // converte para string no formato ISO
          }

          // Obtém a data e hora atuais mais 7 dias
          const getDataValidade = () => {
            const data = new Date();
            data.setDate(data.getDate() + 7); // adiciona 7 dias à data atual
            return data.toISOString(); // converte para string no formato ISO
          }
          setProgress(40);

          // Adicionar documento ao Firestore
          const docRef = await addDoc(collection(db, "estudio"), {
            nomeEstudio: formData.nome,
            nickname: formData.link,
            userID: user.uid,
            whatsapp: `55${formData.ddd.replace(/\s+/g, '')}${formData.celular.replace(/\s+/g, '')}`,
            perfil: downloadURL,
            imgBio1: '', imgBio2: '', imgBio3: '', imgBio4: '', imgBio5: '', imgBio6: '', imgBio7: '',
            tema: formData.cor, fonte: formData.fonte, estilo: formData.estilo, 
            sobre:false, descricao:'', capaSobre:'',
            modoTest:true, statusConta: true, plano: 'Teste', dataConta: getDataAtual(), dataVal:getDataValidade(),
             proxCobranca:'', lastPagData:'', lastPagValor:'', 
          });

          setProgress(80);
          // Agora que o documento foi criado, atualize-o para adicionar o campo iD_estudio com o ID do documento criado
    await setDoc(docRef, { iD_estudio: docRef.id }, { merge: true });

    // Busque o documento na coleção 'user' onde o campo 'uid' seja igual ao ID do usuário atual
    const q = query(collection(db, "user"), where("uid", "==", user.uid));
    const querySnapshot = await getDocs(q);

    // Atualize o campo 'estudio' com o ID do documento que foi criado na coleção 'estudio'
    querySnapshot.forEach((doc) => {
      setDoc(doc.ref, { estudio: docRef.id, pos: true }, { merge: true });
    });

    

    console.log("Documento escrito com o ID: ", docRef.id);
    setProgress(100);

    
   
    EnviarMensagemAPI({
      numero: `55${formData.ddd.replace(/\s+/g, '')}${formData.celular.replace(/\s+/g, '')}`,
      nomeEstudio: `${formData.nome}`,
    });
    

    setTimeout(() => {
      navigate('/home');
  }, 2000);

  
  });
      }
    );
  };

  useEffect(() => {
    if (!formData.estilo) { 
      setFormData(prevState => ({
        ...prevState,
        estilo: "estiloN" 
      }));
    }
  }, []);


    return(
        <div className='pr'>
          {showProgress && <ProgressoSavePos progress={progress} />}
            <img style={{ width: "104px"}}
                    src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2FKtalus%20Logo%20H.png?alt=media&token=6ded890b-555f-41b2-8bfb-fad7700339df&_gl=1*wr9qza*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NzY0MjI4Ny4yNTcuMS4xNjk3NjQyMzIwLjI3LjAuMA.."
                    alt="Ktalus"/>

<div className="pr--prevCont">
            <div className="pr--prevCont--title">Preview</div>
            <div className={`previewPerfil ${formData.fonte ? fontPage[formData.fonte] || '' : ''} ${formData.cor ? colorPage[formData.cor] || '' : ''}`}>
                  <div className='previewPerfilb'></div>
                  
                  <img className='Prev--cantoD' 
                  src={getImageURL(formData.estilo, formData.cor)}
                  alt='' style={{ display: formData.estilo === 'estiloN' ? 'none' : 'flex' }}/>

                  <img className='Prev--cantoC' 
                  src={getImageURL(formData.estilo, formData.cor)}
                  alt='' style={{ display: formData.estilo === 'estiloN' ? 'none' : 'flex' }}/>

                  <img className='Prev--cantoB' 
                  src={getImageURL(formData.estilo, formData.cor)}
                  alt=''style={{ display: formData.estilo === 'estiloN' ? 'none' : 'flex' }}/>

                  <img className='Prev--cantoA' 
                  src={getImageURL(formData.estilo, formData.cor)}
                  alt=''style={{ display: formData.estilo === 'estiloN' ? 'none' : 'flex' }}/>




                  <div className={`prepreviewPerfil-borda ${formData.cor ? colorBorder[formData.cor] || '' : ''}`}>
                    <img className="prepreviewPerfil-foto" src={initialCroppedPhotoURL} alt="" background="#fff"/>
                  </div>
                  {formData.nome}
                </div>
                <p className='p--Info'>Você poderá mudar a personalização depois</p>

          </div>

    <div className='pr--labelsPerso'>

    <label>Escolha um estilo:({formData.estilo}) </label>
    <div className='estilo'>

      <div id='estiloN' className={`estilo--container ${formData.estilo === "estiloN" ? "estiloActive" : ""}`} onClick={() => handleEstiloSelect("estiloN")}>
        <img className='estilo--img' src='https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Festilos%2F01%20none.png?alt=media&token=8f2ae227-56e9-480e-ada2-16274cde4446&_gl=1*14hlihh*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4MDkxOC4xOTUuMS4xNjk2MjgyMjAwLjYwLjAuMA..'
        alt=''/>
      </div>

      <div id='estilo01' className={`estilo--container ${formData.estilo === "estilo1" ? "estiloActive" : ""}`} onClick={() => handleEstiloSelect("estilo1")}>
        <img className='estilo--img' src='https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Festilos%2F02%20style.png?alt=media&token=90394ec6-f223-4f9e-8338-e949d3d8b792&_gl=1*xwdd7o*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4MDkxOC4xOTUuMS4xNjk2MjgyMjQ1LjE1LjAuMA..'
        alt=''/>
      </div>

      <div id='estilo02' className={`estilo--container ${formData.estilo === "estilo2" ? "estiloActive" : ""}`} onClick={() => handleEstiloSelect("estilo2")}>
        <img className='estilo--img' src='https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Festilos%2F03%20style.png?alt=media&token=1b594710-1ede-436c-9b71-75e3fc232217&_gl=1*k0sil4*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4MDkxOC4xOTUuMS4xNjk2MjgyNDMwLjYwLjAuMA..'
        alt=''/>
      </div>

      <div id='estilo03' className={`estilo--container ${formData.estilo === "estilo3" ? "estiloActive" : ""}`} onClick={() => handleEstiloSelect("estilo3")}>
        <img className='estilo--img' src='https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Festilos%2F04%20style.png?alt=media&token=7121066f-98de-4a58-a5c4-27ce4ae7c108&_gl=1*4b0kjh*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4MDkxOC4xOTUuMS4xNjk2MjgyNDQxLjQ5LjAuMA..'
        alt=''/>
      </div>

    </div>
                
   
                
</div>
      <div className='pr--buttons'>
      <button className='pr--back' onClick={onBack} >Voltar</button>
        <button className='pr--next' onClick={handleSave} >Salvar</button>
      </div>
    

    </div>
  );
}

export default Perso2;