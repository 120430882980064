import './items.css';
import { LuFolderEdit } from "react-icons/lu";
import { MdDeleteForever } from "react-icons/md";
import { useState } from 'react';
import EditSecao from './EditSecao';
import DeleteSecao from './DeleteSecao'; // Importe o componente DeleteSecao

function SettingsSecao({ eID, scID, nmSecao, setIsSettingsVisible }) {
  const [editSecaoVisible, setEditSecaoVisible] = useState(false);
  const [deleteSecaoVisible, setDeleteSecaoVisible] = useState(false); // Estado para controlar a visibilidade do componente DeleteSecao

  const openEditSecao = () => {
    setEditSecaoVisible(true);
  };

  const closeEditSecao = () => {
    setEditSecaoVisible(false);
  };

  const openDeleteSecao = () => {
    setDeleteSecaoVisible(true);
  };

  const closeDeleteSecao = () => {
    setDeleteSecaoVisible(false);
  };

  return (
    <div className='boxSet'>
      <div className='setEdit' onClick={openEditSecao}>
        <LuFolderEdit className='iconSet'/>
        Editar nome da Seção
      </div>
      <div className='setDel' onClick={openDeleteSecao}>
        <MdDeleteForever className='iconSet'/>
        Deletar Seção
      </div>

      {editSecaoVisible && (
        <EditSecao scID={scID} nome={nmSecao} handleCancelar={closeEditSecao} setIsSettingsVisible={setIsSettingsVisible} />
      )}

      {deleteSecaoVisible && (
        <DeleteSecao eID={eID} scID={scID} nome={nmSecao} handleCancelar={closeDeleteSecao} setIsSettingsVisible={setIsSettingsVisible} />
      )}
    </div>
  );
}

export default SettingsSecao;
