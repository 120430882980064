import { Cancel, CheckCircle } from '@mui/icons-material';
import CropIcon from '@mui/icons-material/Crop';
import { Box, Button, DialogActions, DialogContent, Slider, Typography } from '@mui/material';
import React, { useState } from "react";
import Cropper from 'react-easy-crop';
import "../../../components/C_Body/Items/items.css";
import getCroppedImg from './utils/cropImage';

const CropEasy = ({ photoURL, setOpenCrop, setCroppedPhotoURL, setAlert, setLoading,  handleDeleteImage, selectedInput  }) => {
    
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [rotation, setRotation] = useState(0);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

    const cropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }

    const cropAndSetImage = async () => {
        try {
            const { file, url } = await getCroppedImg(photoURL, croppedAreaPixels, rotation);

            // Defina a URL da imagem cortada no estado
            setCroppedPhotoURL(url);

            setOpenCrop(false);
        } catch (error) {
            setAlert({ isAlert: true, severity: "error", message: error.message, timeout: 5000, location: "modal" })
            console.log(error)
        }
    }

    const clearSelectedInput = () => {
        if (selectedInput) {
          document.getElementById(selectedInput).value = "";
        }
        handleDeleteImage(selectedInput);
        setOpenCrop(false);
      };
      

    return (
        <div className='comp' style={{background:"#333"}}>
            <div>
                <DialogContent sx={{
                    background: "#333",
                    position: "relative",
                    height: 400,
                    width: 300,
                    margin: "auto"
                }}>
                    <Cropper
                        image={photoURL}
                        crop={crop}
                        zoom={zoom}
                        rotation={rotation}
                        aspect={4 / 5}
                        onZoomChange={setZoom}
                        onRotationChange={setRotation}
                        onCropChange={setCrop}
                        onCropComplete={cropComplete}
                    />
                </DialogContent>
            </div>

            <div>
                <DialogActions sx={{ display: "flex", flexDirection: "row", mx: 3, my: 2,  }}>
                    <Box sx={{ display: "flex",width:'100%', flexDirection: "column", gap: 2, margin: "auto" }}>
                       
                       <Box sx={{ display: "flex", flexDirection: "row", gap:'16px', margin: "auto", width:'100%', color:'#fff'  }}>
                        <Box sx={{ width:'100%', mb:1}}>
                            <Box>
                            <Typography>Zoom:</Typography>
                            <Slider
                            valueLabelDisplay='auto'
                            valueLabelFormat={zoomPercent}
                            min={1} max={3}
                            step={0.1}
                            value={zoom}
                            onChange={(e,zoom)=> setZoom(zoom)}

                            />
                            </Box>
                        </Box>

                        <Box sx={{width:'100%', mb:1}}>
                            <Box>
                            <Typography>Rotacionar:</Typography>
                            <Slider
                            valueLabelDisplay='auto'
                            
                            min={0} max={360}
                            
                            value={rotation}
                            onChange={(e,rotation)=> setRotation(rotation)}

                            />
                            </Box>
                        </Box>

                        </Box >
                        <Box sx={{ display: "flex", flexDirection: "row", gap: 2, margin: "auto", width:'90%',}}>
                        <Button sx={{ color: "#FEEC6E", border: "1px solid #FEEC6E" }}
                            variant='outlined'
                            startIcon={<Cancel />}
                            onClick={clearSelectedInput}
                        >
                            Cancelar
                        </Button>
                        <Button variant='contained' startIcon={<CheckCircle />} onClick={cropAndSetImage} 
                        sx={{ background: "#FEEC6E", color:'#000' }}>
                            Salvar
                        </Button>
                        </Box>
                    </Box>
                </DialogActions>
            </div>
        </div>
    )
}

export default CropEasy;


const zoomPercent = (value)=>{
    return `${Math.round(value * 100)}%`
}