

export const getImageURL = (estilo, cor) => {
    // Pode ser uma lógica mais complexa aqui, usando switch ou if-else
    if (estilo === 'estilo1' && cor === 'vermelho') {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20ver.png?alt=media&token=43f9552e-3285-42d5-a4d5-fc228df8cc99&_gl=1*1rphwk0*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg4MjE0LjYwLjAuMA..';
    } else if (estilo === 'estilo1' && cor === 'laranja') {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20lan.png?alt=media&token=13b3227d-9867-4fcc-99cd-e10c9aff3387&_gl=1*dkm9uk*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg5MzI3LjYwLjAuMA..';
    } else if (estilo === 'estilo1' && cor === 'amarelo') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20ama.png?alt=media&token=a81d5561-1e3c-46eb-b3d3-ab6118b9c053&_gl=1*g7f4li*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg4MjQ2LjI4LjAuMA..';
    } else if (estilo === 'estilo1' && cor === 'verde') {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20verd.png?alt=media&token=bf34a3f6-9465-4cd4-9581-2ea7d81cb022&_gl=1*1e6z1y0*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg5NDMwLjU4LjAuMA..';
    } else if (estilo === 'estilo1' && cor === 'ciano') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20cia.png?alt=media&token=8525c3c4-b2e1-4fec-acce-b60bfa26c037&_gl=1*15q9t0i*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg5NDUxLjM3LjAuMA..';
    } else if (estilo === 'estilo1' && cor === 'azul') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20azul.png?alt=media&token=5ada0c3c-ea23-47d5-ba67-d97a30ea5c5c&_gl=1*1tjv2vx*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg5NDkyLjYwLjAuMA..';
    } else if (estilo === 'estilo1' && cor === 'roxo') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20rox.png?alt=media&token=04610136-270d-478e-b403-7dcf6f196a74&_gl=1*1gg9ose*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg5NTEyLjQwLjAuMA..';
    } else if (estilo === 'estilo1' && cor === 'rosa') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20ros.png?alt=media&token=cc1aee4f-7364-45c1-9ff1-3e4c10d3d74a&_gl=1*poz97x*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg5NTI4LjI0LjAuMA..';
    } else if (estilo === 'estilo1' && cor === 'magenta') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20mag.png?alt=media&token=dd32d22e-b80a-4caf-9fa6-2c9fa3f21618&_gl=1*16gp1v1*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg5NTQ0LjguMC4w';
    } else if (estilo === 'estilo1' && cor === 'branco') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20bra.png?alt=media&token=4e782170-fdfe-4fc5-83fb-2b3ef120bb8b&_gl=1*clgixj*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg5NTU2LjYwLjAuMA..';
    } 
    
    else if (estilo === 'estilo1' && cor === 'goldVermelho') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20ama.png?alt=media&token=a81d5561-1e3c-46eb-b3d3-ab6118b9c053&_gl=1*g7f4li*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg4MjQ2LjI4LjAuMA..';
    }else if (estilo === 'estilo1' && cor === 'goldVerde') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20ama.png?alt=media&token=a81d5561-1e3c-46eb-b3d3-ab6118b9c053&_gl=1*g7f4li*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg4MjQ2LjI4LjAuMA..';
    }else if (estilo === 'estilo1' && cor === 'goldAzul') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20ama.png?alt=media&token=a81d5561-1e3c-46eb-b3d3-ab6118b9c053&_gl=1*g7f4li*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg4MjQ2LjI4LjAuMA..';
    }else if (estilo === 'estilo1' && cor === 'goldRoxo') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20ama.png?alt=media&token=a81d5561-1e3c-46eb-b3d3-ab6118b9c053&_gl=1*g7f4li*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg4MjQ2LjI4LjAuMA..';
    }else if (estilo === 'estilo1' && cor === 'goldAmarelo') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20ama.png?alt=media&token=a81d5561-1e3c-46eb-b3d3-ab6118b9c053&_gl=1*g7f4li*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg4MjQ2LjI4LjAuMA..';
    }

    else if (estilo === 'estilo1' && cor === 'silverVermelho') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20bra.png?alt=media&token=4e782170-fdfe-4fc5-83fb-2b3ef120bb8b&_gl=1*clgixj*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg5NTU2LjYwLjAuMA..';
    }else if (estilo === 'estilo1' && cor === 'silverVerde') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20bra.png?alt=media&token=4e782170-fdfe-4fc5-83fb-2b3ef120bb8b&_gl=1*clgixj*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg5NTU2LjYwLjAuMA..';
    }else if (estilo === 'estilo1' && cor === 'silverAzul') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20bra.png?alt=media&token=4e782170-fdfe-4fc5-83fb-2b3ef120bb8b&_gl=1*clgixj*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg5NTU2LjYwLjAuMA..';
    }else if (estilo === 'estilo1' && cor === 'silverRoxo') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20bra.png?alt=media&token=4e782170-fdfe-4fc5-83fb-2b3ef120bb8b&_gl=1*clgixj*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg5NTU2LjYwLjAuMA..';
    }else if (estilo === 'estilo1' && cor === 'silverAmarelo') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20bra.png?alt=media&token=4e782170-fdfe-4fc5-83fb-2b3ef120bb8b&_gl=1*clgixj*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg5NTU2LjYwLjAuMA..';
    }

    else if (estilo === 'estilo1' && cor === 'vermelhoVerde') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20ver.png?alt=media&token=43f9552e-3285-42d5-a4d5-fc228df8cc99&_gl=1*1rphwk0*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg4MjE0LjYwLjAuMA..';
    }else if (estilo === 'estilo1' && cor === 'laranjaCiano') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20lan.png?alt=media&token=13b3227d-9867-4fcc-99cd-e10c9aff3387&_gl=1*dkm9uk*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg5MzI3LjYwLjAuMA..';
    }else if (estilo === 'estilo1' && cor === 'verdeVermelho') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20verd.png?alt=media&token=bf34a3f6-9465-4cd4-9581-2ea7d81cb022&_gl=1*1e6z1y0*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg5NDMwLjU4LjAuMA..';
    }else if (estilo === 'estilo1' && cor === 'azulAmarelo') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20azul.png?alt=media&token=5ada0c3c-ea23-47d5-ba67-d97a30ea5c5c&_gl=1*1tjv2vx*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg5NDkyLjYwLjAuMA..';
    }




        else if (estilo === 'estilo2' && cor === 'vermelho') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20ver.png?alt=media&token=84d7ffb8-37d3-4b93-a83d-ba5d21045762&_gl=1*9gi7pt*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NjIzLjIyLjAuMA..';
      } else if (estilo === 'estilo2' && cor === 'laranja') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20lan.png?alt=media&token=77e85cbc-644b-45e4-8120-6c1128597b89&_gl=1*cptgls*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NjM5LjYuMC4w';
      } else if (estilo === 'estilo2' && cor === 'amarelo') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20ama.png?alt=media&token=94e535d2-070b-40c2-9328-45a719d7b718&_gl=1*1ow957m*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NjU1LjYwLjAuMA..';
      } else if (estilo === 'estilo2' && cor === 'verde') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20verd.png?alt=media&token=020adab3-da6c-4333-9dd0-964743289ae2&_gl=1*yezvxf*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NjY4LjQ3LjAuMA..';
      } else if (estilo === 'estilo2' && cor === 'ciano') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20cia.png?alt=media&token=979de97e-47f8-4e53-ae2f-ca6d41938b65&_gl=1*4l4ltm*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NjgxLjM0LjAuMA..';
      } else if (estilo === 'estilo2' && cor === 'azul') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20azu.png?alt=media&token=8ccce2c9-3813-492c-87fc-dbef40815c8c&_gl=1*ebabsd*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NjkxLjI0LjAuMA..';
      } else if (estilo === 'estilo2' && cor === 'roxo') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20rox.png?alt=media&token=85cb560c-9ba8-4265-af40-0a22b906239a&_gl=1*qwjsu0*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NzAzLjEyLjAuMA..';
      } else if (estilo === 'estilo2' && cor === 'rosa') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20ros.png?alt=media&token=c6adeeae-c5f7-4c8d-99d1-db0c2cb91a18&_gl=1*1pr4qfp*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NzE0LjEuMC4w';
      } else if (estilo === 'estilo2' && cor === 'magenta') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20mag.png?alt=media&token=009ea0a0-534a-401d-9e53-e0ffc201ac8a&_gl=1*97esqf*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NzI0LjYwLjAuMA..';
      } else if (estilo === 'estilo2' && cor === 'branco') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20bra.png?alt=media&token=34d4d16d-245c-4d51-b5e5-6face49e4213&_gl=1*9n0vvm*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NzM0LjUwLjAuMA..';
      }


      else if (estilo === 'estilo2' && cor === 'goldVermelho') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20ama.png?alt=media&token=94e535d2-070b-40c2-9328-45a719d7b718&_gl=1*1ow957m*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NjU1LjYwLjAuMA..';
    }else if (estilo === 'estilo2' && cor === 'goldVerde') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20ama.png?alt=media&token=94e535d2-070b-40c2-9328-45a719d7b718&_gl=1*1ow957m*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NjU1LjYwLjAuMA..';
    }else if (estilo === 'estilo2' && cor === 'goldAzul') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20ama.png?alt=media&token=94e535d2-070b-40c2-9328-45a719d7b718&_gl=1*1ow957m*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NjU1LjYwLjAuMA..';
    }else if (estilo === 'estilo2' && cor === 'goldRoxo') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20ama.png?alt=media&token=94e535d2-070b-40c2-9328-45a719d7b718&_gl=1*1ow957m*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NjU1LjYwLjAuMA..';
    }else if (estilo === 'estilo2' && cor === 'goldAmarelo') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20ama.png?alt=media&token=94e535d2-070b-40c2-9328-45a719d7b718&_gl=1*1ow957m*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NjU1LjYwLjAuMA..';
    }

    else if (estilo === 'estilo2' && cor === 'silverVermelho') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20bra.png?alt=media&token=34d4d16d-245c-4d51-b5e5-6face49e4213&_gl=1*9n0vvm*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NzM0LjUwLjAuMA..';
    }else if (estilo === 'estilo2' && cor === 'silverVerde') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20bra.png?alt=media&token=34d4d16d-245c-4d51-b5e5-6face49e4213&_gl=1*9n0vvm*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NzM0LjUwLjAuMA..';
    }else if (estilo === 'estilo2' && cor === 'silverAzul') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20bra.png?alt=media&token=34d4d16d-245c-4d51-b5e5-6face49e4213&_gl=1*9n0vvm*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NzM0LjUwLjAuMA..';
    }else if (estilo === 'estilo2' && cor === 'silverRoxo') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20bra.png?alt=media&token=34d4d16d-245c-4d51-b5e5-6face49e4213&_gl=1*9n0vvm*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NzM0LjUwLjAuMA..';
    }else if (estilo === 'estilo2' && cor === 'silverAmarelo') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20bra.png?alt=media&token=34d4d16d-245c-4d51-b5e5-6face49e4213&_gl=1*9n0vvm*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NzM0LjUwLjAuMA..';
    }

    else if (estilo === 'estilo2' && cor === 'vermelhoVerde') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20ver.png?alt=media&token=84d7ffb8-37d3-4b93-a83d-ba5d21045762&_gl=1*9gi7pt*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NjIzLjIyLjAuMA..';
    }else if (estilo === 'estilo2' && cor === 'laranjaCiano') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20lan.png?alt=media&token=77e85cbc-644b-45e4-8120-6c1128597b89&_gl=1*cptgls*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NjM5LjYuMC4w';
    }else if (estilo === 'estilo2' && cor === 'verdeVermelho') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20verd.png?alt=media&token=020adab3-da6c-4333-9dd0-964743289ae2&_gl=1*yezvxf*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NjY4LjQ3LjAuMA..';
    }else if (estilo === 'estilo2' && cor === 'azulAmarelo') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20azu.png?alt=media&token=8ccce2c9-3813-492c-87fc-dbef40815c8c&_gl=1*ebabsd*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NjkxLjI0LjAuMA..';
    }




      else if (estilo === 'estilo3' && cor === 'vermelho') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20ver.png?alt=media&token=625f2a29-509d-4603-b86a-dab688d96658&_gl=1*stohcl*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0Nzk0LjYwLjAuMA..';
      } else if (estilo === 'estilo3' && cor === 'laranja') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20lan.png?alt=media&token=4f53ed04-15e7-4bc6-8c9b-f565973c42db&_gl=1*pal4e5*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0ODA4LjQ2LjAuMA..';
      } else if (estilo === 'estilo3' && cor === 'amarelo') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20ama.png?alt=media&token=fb476ad1-1620-400b-bcf5-74236bc5da59&_gl=1*dnnqr*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0ODI0LjMwLjAuMA..';
      } else if (estilo === 'estilo3' && cor === 'verde') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20verd.png?alt=media&token=cdad931c-f10a-42e3-9be0-143d053b8867&_gl=1*12yesgl*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0ODM2LjE4LjAuMA..';
      } else if (estilo === 'estilo3' && cor === 'ciano') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20cia.png?alt=media&token=61dd45eb-81d5-4be6-a5fa-968389e712f5&_gl=1*1hu5krv*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0ODQ4LjYuMC4w';
      } else if (estilo === 'estilo3' && cor === 'azul') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20azu.png?alt=media&token=519a022d-f58a-408a-a28d-4b3edae553bc&_gl=1*10ee0jj*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0OTIwLjU4LjAuMA..';
      } else if (estilo === 'estilo3' && cor === 'roxo') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20rox.png?alt=media&token=80b006d1-141c-423f-8267-18469ff84f95&_gl=1*7sl7li*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0OTM2LjQyLjAuMA..';
      } else if (estilo === 'estilo3' && cor === 'rosa') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20ros.png?alt=media&token=e84b6932-8d9d-453b-ae7b-7bfce631bb9a&_gl=1*1dd76ck*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0OTUxLjI3LjAuMA..';
      } else if (estilo === 'estilo3' && cor === 'magenta') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20mag.png?alt=media&token=7b57b9a7-0935-41de-a2f9-6bae491a8aed&_gl=1*l18eip*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0OTYyLjE2LjAuMA..';
      } else if (estilo === 'estilo3' && cor === 'branco') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20bra.png?alt=media&token=11def282-e253-47ba-a952-4c3ed089034e&_gl=1*1axa9z3*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0OTczLjUuMC4w';
      }



      else if (estilo === 'estilo3' && cor === 'goldVermelho') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20ama.png?alt=media&token=fb476ad1-1620-400b-bcf5-74236bc5da59&_gl=1*dnnqr*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0ODI0LjMwLjAuMA..';
    }else if (estilo === 'estilo3' && cor === 'goldVerde') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20ama.png?alt=media&token=fb476ad1-1620-400b-bcf5-74236bc5da59&_gl=1*dnnqr*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0ODI0LjMwLjAuMA..';
    }else if (estilo === 'estilo3' && cor === 'goldAzul') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20ama.png?alt=media&token=fb476ad1-1620-400b-bcf5-74236bc5da59&_gl=1*dnnqr*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0ODI0LjMwLjAuMA..';
    }else if (estilo === 'estilo3' && cor === 'goldRoxo') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20ama.png?alt=media&token=fb476ad1-1620-400b-bcf5-74236bc5da59&_gl=1*dnnqr*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0ODI0LjMwLjAuMA..';
    }else if (estilo === 'estilo3' && cor === 'goldAmarelo') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20ama.png?alt=media&token=fb476ad1-1620-400b-bcf5-74236bc5da59&_gl=1*dnnqr*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0ODI0LjMwLjAuMA..';
    }

    else if (estilo === 'estilo3' && cor === 'silverVermelho') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20bra.png?alt=media&token=11def282-e253-47ba-a952-4c3ed089034e&_gl=1*1axa9z3*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0OTczLjUuMC4w';
    }else if (estilo === 'estilo3' && cor === 'silverVerde') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20bra.png?alt=media&token=11def282-e253-47ba-a952-4c3ed089034e&_gl=1*1axa9z3*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0OTczLjUuMC4w';
    }else if (estilo === 'estilo3' && cor === 'silverAzul') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20bra.png?alt=media&token=11def282-e253-47ba-a952-4c3ed089034e&_gl=1*1axa9z3*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0OTczLjUuMC4w';
    }else if (estilo === 'estilo3' && cor === 'silverRoxo') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20bra.png?alt=media&token=11def282-e253-47ba-a952-4c3ed089034e&_gl=1*1axa9z3*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0OTczLjUuMC4w';
    }else if (estilo === 'estilo3' && cor === 'silverAmarelo') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20bra.png?alt=media&token=11def282-e253-47ba-a952-4c3ed089034e&_gl=1*1axa9z3*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0OTczLjUuMC4w';
    }

    else if (estilo === 'estilo3' && cor === 'vermelhoVerde') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20ver.png?alt=media&token=625f2a29-509d-4603-b86a-dab688d96658&_gl=1*stohcl*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0Nzk0LjYwLjAuMA..';
    }else if (estilo === 'estilo3' && cor === 'laranjaCiano') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20lan.png?alt=media&token=4f53ed04-15e7-4bc6-8c9b-f565973c42db&_gl=1*pal4e5*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0ODA4LjQ2LjAuMA..';
    }else if (estilo === 'estilo3' && cor === 'verdeVermelho') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20verd.png?alt=media&token=cdad931c-f10a-42e3-9be0-143d053b8867&_gl=1*12yesgl*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0ODM2LjE4LjAuMA..';
    }else if (estilo === 'estilo3' && cor === 'azulAmarelo') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20azu.png?alt=media&token=519a022d-f58a-408a-a28d-4b3edae553bc&_gl=1*10ee0jj*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0OTIwLjU4LjAuMA..';
    }
    // ... assim por diante
    return 'URL_IMAGEM_DEFAULT';
  };


  const somethingElse = () => {};

  export default somethingElse;