import React, { useEffect, useState, useRef } from 'react';
import '../../components/C_Body/Items/items.css'
import HeaderPage from "../../components/C_Body/HeaderPage";
import { BsFileImage, BsTrash } from "react-icons/bs";
import { AiFillCloseSquare } from "react-icons/ai";
import { BiEdit, BiImageAdd } from "react-icons/bi";
import firebase from 'firebase/app';
import "firebase/firestore";
import DefinirCrs from './P04DefinirCrs';
import { updateDoc, getDocs, query, where, getFirestore, collection, doc, } from "firebase/firestore";
import { getStorage, ref, deleteObject } from "firebase/storage";
import { db } from '../../services/firebaseConfig';
import CropEasy16x9Sobre from './crop/CropEasy16x9Sobre';
import P04Carrossel from './P04Carrossel';

function P04PaginaInicial ({ estudio, nome, setComponenteAtivo, cImg1, cImg2, cImg3, cImg4, cImg5, cImg6, cImg7, sobre, descricao, capaSobre }){
   

    const [editDescricao, setEditDescricao] = useState(false)

    const updateImgBioField = async (estudioId, field) => {
        try {
          const db = getFirestore();
          const estudioRef = doc(db, "estudio", estudioId);
          
          await updateDoc(estudioRef, {
            [field]: ""
          });
      
          console.log("Campo atualizado com sucesso");
        } catch (error) {
          console.error("Erro ao atualizar o campo: ", error);
        }
      };

     
      const updateSobreField = async (estudioId, value) => {
        try {
          const db = getFirestore();
          const estudioRef = doc(db, "estudio", estudioId);
          
          await updateDoc(estudioRef, {
            "sobre": value
          });
      
          console.log("Campo 'sobre' atualizado com sucesso");
        } catch (error) {
          console.error("Erro ao atualizar o campo 'sobre': ", error);
        }
    };

    const [descricaoState, setDescricaoState] = useState(descricao || "");

    useEffect(() => {
        setDescricaoState(descricao);
        
    }, [descricao]);

    const atualizarCampoFirestore = async () => {
        try {
            const estudioRef = doc(db, "estudio", estudio); // Referência para o documento
            await updateDoc(estudioRef, {
                descricao: descricaoState,
            });
            
            console.log("Documento atualizado com sucesso!");
            
            // Seta o estado 'editNome' como false após atualizar o documento com sucesso
            setEditDescricao(false);
        } catch (error) {
            console.error("Erro atualizando documento: ", error);
        }
    };
     


   // A função para lidar com o pressionamento de teclas no textarea
// Esta função é acionada quando o usuário pressiona uma tecla enquanto o foco está no textarea
const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        setDescricaoState((prevState) => `${prevState}\n`);
    }
};

// A função para lidar com mudanças normais no textarea
// Esta função é acionada quando o valor do campo descricao muda
const handleNomeChange = (e) => {
    const valor = e.target.value;
    if (valor.length <= 800) {
        setDescricaoState(valor);
    }
};

// Ao renderizar o componente, este código formata o texto armazenado em descricaoState
// Ele divide o texto por linhas e insere uma quebra de linha HTML entre elas
const textoFormatado = descricaoState ? descricaoState.split('\n').map((str, index, array) => 
    index === array.length - 1 ? str : <>
        {str}
        <br />
    </>
) : '';


        const removerFoto = async () => {
            if (capaSobre) {
                // Criar uma referência para o arquivo no armazenamento do Firebase
                const storage = getStorage();
                const fotoRef = ref(storage, `capasSobre/${estudio}.jpg`); // Substitua pelo caminho correto
        
                // Excluir o arquivo
                try {
                    await deleteObject(fotoRef);
        
                    // Agora, atualize o campo 'perfil' para vazio na base de dados
                    const estudioRef = doc(db, "estudio", estudio);
                    await updateDoc(estudioRef, {
                        capaSobre: "",
                    });
        
                    console.log("Foto e campo de perfil atualizados com sucesso");
        
                } catch (error) {
                    console.error("Erro ao excluir foto: ", error);
                }
            }
        };

const [globalCroppedPhotoURL, setGlobalCroppedPhotoURL] = useState(null);
const [selectedFile, setSelectedFile] = useState(null);
const [openCrop, setOpenCrop] = useState(false);
const [croppedPhotoURL, setCroppedPhotoURL] = useState(globalCroppedPhotoURL);

const fileInputRef = useRef(null);

useEffect(() => {
  setGlobalCroppedPhotoURL(croppedPhotoURL);
}, [croppedPhotoURL, setGlobalCroppedPhotoURL]);

const handleClickUpload = () => {
  fileInputRef.current.click();
};

const handleFileChange = (e) => {
    const file = e.target.files[0];
    const maxFileSize = 20 * 1024 * 1024; // 10 MB em bytes
  
    if (file) {
      // Verifique o tamanho do arquivo
      if (file.size <= maxFileSize) {
        setSelectedFile(file);
        setOpenCrop(true);
      } else {
        alert('O arquivo é muito grande! Por favor, selecione um arquivo de no máximo 10 MB.');
      }
    }
  };
  

const handleRemove = () => {
  setSelectedFile(null); 
  setCroppedPhotoURL(null); 
  setOpenCrop(false); 
  if (fileInputRef.current) {
    fileInputRef.current.value = null;
  }
};



    return(
        <div className='pgInicialPage'>
            <HeaderPage pg={'Página Inicial'} setComponenteAtivo={setComponenteAtivo} />
        <div className='pgInicialPage--container'>
            
            <P04Carrossel estudio={estudio} cImg1={cImg1} cImg2={cImg2} cImg3={cImg3} cImg4={cImg4} cImg5={cImg5} cImg6={cImg6} cImg7={cImg7}/>


            <div className='pgInicialPage--saibamais'>

                <div className='saibamais--title'>
                    Mostrar botão<br/> "Saber mais de ..."

                 {sobre === true ? (<div className="toggleOnOut active" onClick={() => updateSobreField(estudio, false)}>
                        <p className="toggleOnText">ON</p>
                        <div className="toggleInON"></div>
                    </div>):

                    (<div className="toggleOffOut active" onClick={() => updateSobreField(estudio, true)}>
                        <div className="toggleInOff"></div>
                        <p className="toggleOffText">OFF</p>
                    </div>)}
                </div>

                <div className='saibamais--container' style={{ display: sobre ? 'flex' : 'none' }}>

                    <div className='sobrePage--fotoContainer'>
                    <input  type="file" id="fotoperfil"  accept=".jpg, .jpeg, .png, .webp" 
                    ref={fileInputRef}  onChange={handleFileChange} style={{ display: 'none' }}
                   
                />
                    {!capaSobre ?(<div className='fotoContainer--fotoSMEmpty' onClick={handleClickUpload}>
                           <BiImageAdd style={{fontSize:'32px'}}/> Adicionar foto
                        </div>):

                        (<div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                        <img className='fotoContainer--fotoSM'
                        src={capaSobre} alt=''/>

                        <button className='fotoContainer--remover'  onClick={removerFoto} ><BsTrash />Remover</button></div>)}
                    
                        {openCrop && (
                            <div className={openCrop ? "crop-popup" : "crop-popup-hidden"}>
                            <CropEasy16x9Sobre 
                            photoURL={URL.createObjectURL(selectedFile)} 
                            setOpenCrop={setOpenCrop} 
                            setCroppedPhotoURL={setCroppedPhotoURL} 
                            handleRemove={handleRemove}
                            estudio={estudio}
                            />
                            </div>
                            )}
                    
                    </div>

                    <div className='perfilPage--nomeContainer'>
                        <div className='descricaotittle'>
                            Descrição
                            {!editDescricao && <div className='nomeContainer--buttonEdit' onClick={()=> setEditDescricao(true)}><BiEdit/></div>}
                        </div>

                        {!editDescricao && <div className='conteudoSobre'>
                            {textoFormatado}
                        </div>}

                        {editDescricao && <div className='nomeContainer--DescEdit'>
                            <div className='nomeEditInput' >
                                <textarea type='text' name='nomeEdit' style={{height:'200px', width:'90%', borderRadius:'8px'}}
                              value={descricaoState} onChange={handleNomeChange} onKeyDown={handleKeyDown} /> 
                            <div className='contadorDesc'><span className='caracteres'>{descricaoState ? descricaoState.length : 0}</span>/800</div>
                            </div>
                            <div className='buttonsEdit' style={{marginTop:'0px'}}>
                                <div className='cancelButtonEdit'onClick={()=> setEditDescricao(false)}>Cancelar</div>
                                <div className='saveButtonEdit' onClick={atualizarCampoFirestore}>Salvar</div>
                        </div>
                        
                    </div>}


                    </div>


                </div>

            </div>


        </div>
        
        
        </div>
    )
}


export default P04PaginaInicial;



