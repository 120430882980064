import './Catalogo.css'
import Moldura from './Moldura';
import SecaoC from './SecaoC';
import SecaoTop from './SecaoTop';
import { useState, useEffect } from 'react';
import { onSnapshot, query, where, collection, orderBy } from "firebase/firestore";
import { db } from '../../services/firebaseConfig';
import ProgressoC from '../../components/C_Body/Items/progress copy';





function Catalogo({ tema, estilo, fonte, eID, nome, perfil, topON, numero, setMostrarCatalogo }){
    

    const [isFadeOutComplete, setIsFadeOutComplete] = useState(false);


    const [sessoesData, setSessoesData] = useState([]); 

    const fontPage = {
      roboto: 'fontRoboto', slab: 'fontSlab', serif: 'fontSerif'
    }

    const temaParaClasse = {
      vermelho:'pgBranco', laranja:'pgBranco', amarelo:'pgBranco', verde:'pgBranco', ciano:'pgBranco',
      azul:'pgBranco', roxo:'pgBranco', rosa:'pgBranco', magenta:'pgBranco', branco:'pgBranco',
  
      goldVermelho: 'pgVermelho', goldVerde:'pgVerde', goldAzul:'pgAzul', goldRoxo:'pgRoxo', goldAmarelo:'pgAmarelo',
      silverVermelho: 'pgVermelho', silverVerde:'pgVerde', silverAzul:'pgAzul', silverRoxo:'pgRoxo', silverAmarelo:'pgAmarelo',
      vermelhoVerde:'pgVerde', laranjaCiano:'pgCiano', verdeVermelho:'pgVermelho', azulAmarelo:'pgAmarelo',
  
    };

    const cab = {
      vermelho:'cabBranco', laranja:'cabBranco', amarelo:'cabBranco', verde:'cabBranco', ciano:'cabBranco',
      azul:'cabBranco', roxo:'cabBranco', rosa:'cabBranco', magenta:'cabBranco', branco:'cabBranco',
  
      goldVermelho: 'cabVermelho', goldVerde:'cabVerde', goldAzul:'cabAzul', goldRoxo:'cabRoxo', goldAmarelo:'cabAmarelo',
      silverVermelho: 'cabVermelho', silverVerde:'cabVerde', silverAzul:'cabAzul', silverRoxo:'cabRoxo', silverAmarelo:'cabAmarelo',
      vermelhoVerde:'cabVerde', laranjaCiano:'cabCiano', verdeVermelho:'cabVermelho', azulAmarelo:'cabAmarelo',
  
    };

    const bordaPerfil = {
      vermelho: 'bordaVermelho', laranja: 'bordaLaranja', amarelo: 'bordaAmarelo', verde: 'bordaVerde', ciano: 'bordaCiano',
      azul:'bordaAzul', roxo:'bordaRoxo', rosa:'bordaRosa', magenta:'bordaMagenta', branco: 'bordaBranco',

      goldVermelho: 'bordaAmarelo', goldVerde:'bordaAmarelo', goldAzul:'bordaAmarelo', goldRoxo:'bordaAmarelo', goldAmarelo:'bordaAmarelo',

      silverVermelho: 'bordaBranco', silverVerde:'bordaBranco', silverAzul:'bordaBranco', silverRoxo:'bordaBranco', silverAmarelo:'bordaBranco',
  
      vermelhoVerde:'bordaVermelho', laranjaCiano:'bordaLaranja', verdeVermelho:'bordaVerde', azulAmarelo:'bordaAzul',
  }

  const [opacityPgW, setOpacityPgW] = useState(0);

  const [progress, setProgress] = useState(0);

const back = () => {
  setMostrarCatalogo();
}

  // Função para simular o progresso
  const simulateProgress = () => {
     let currentProgress = 0;
     const intervalId = setInterval(() => {
         currentProgress += 20;  // Supondo que cada função incrementa o progresso em 10%
         setProgress(currentProgress);
         if (currentProgress === 100) {
             clearInterval(intervalId);
             // Aguarda 1 segundo antes de mudar para o componente Plataforma
             setTimeout(() => {
              setIsFadeOutComplete(true);
             }, 3000);
         }
     }, 100);  // Supondo que cada função leva 100ms para ser executada
 };




useEffect(() => {
  const q = query(
    collection(db, 'sessoes'),
    where('EstudioID', '==', eID),
    orderBy('ordem', 'asc')
  );

  // Observa as mudanças em tempo real
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const novasSessoes = [];
    querySnapshot.forEach((doc) => {
      novasSessoes.push({ id: doc.id, ...doc.data() });
    });
    setSessoesData(novasSessoes); // Atualiza o estado sessoesData
  });

  // Limpa o listener ao desmontar o componente
  return () => unsubscribe();

}, [eID]);

useEffect(() => {
  if (sessoesData.length > 0) {  // Verifica se sessoesData foi carregado
      simulateProgress();  // Chama a função simulateProgress
      const timer = setTimeout(() => {
          setOpacityPgW(1);  // Muda a opacidade de #pgW para 1 após 3 segundos
      }, 2000);
      // Limpa o timer ao desmontar o componente
      return () => clearTimeout(timer);
  }
}, [sessoesData]);   // Este useEffect será executado sempre que sessoesData for atualizado




      useEffect(() => {
        // Adiciona um novo estado no histórico do navegador
        // Isso faz com que o botão voltar possa ser ativado
        window.history.pushState(null, null, window.location.pathname);
    
        const handlePopState = (event) => {
          event.preventDefault(); // previne o comportamento padrão de voltar
          setMostrarCatalogo(); // chama a função handleClick
          //window.history.pushState(null, null, window.location.pathname); // reinsere o estado
        };
    
        // Adiciona o evento popstate ao window
        window.addEventListener('popstate', handlePopState);
    
        // Remove o evento ao desmontar o componente
        return () => {
          window.removeEventListener('popstate', handlePopState);
        };
    
      }, []);

    return(

        <>
       
       

        <div id="pgW" style={{ opacity: opacityPgW }}   className={`${tema ? temaParaClasse[tema] || '' : ''} ${fonte ? fontPage[fonte] || '' : ''} `}>  
            {/*<Moldura/>*/}
            
            <div id='page'>
                
                <div className="cabecalho" onClick={back}>
                    <div className={`perfilBC ${tema ? bordaPerfil[tema] || '' : ''}`}>
                        <div className="perfilPC">
                            <img id="ph1" src={perfil || 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2FAnyConv.com__fotonull.webp?alt=media&token=a2f03184-202c-4db9-a1bd-a62c3236b71d'} alt="" className="perfilPC"/>
                            
                        </div>
                    </div>
                    <div>{nome}</div> 
                </div>

                <SecaoTop eID={eID} cor={tema} style={{ display: topON ? 'flex' : 'none' }} estilo={estilo} numero={numero} />
                
                {sessoesData.map((sessao, index) => (
                    <SecaoC key={index} {...sessao} cor={tema}  estilo={estilo} numero={numero}/>
                ))}
        
            </div>
        </div>

        <div id="pgW2" className={`${tema ? temaParaClasse[tema] || '' : ''} ${fonte ? fontPage[fonte] || '' : ''} ${isFadeOutComplete ? 'fade-out' : ''} `}>
        <ProgressoC progress={progress}/>
         </div>

        
        </>
        
    )
}



export default Catalogo;