import React, { useState } from "react";
import { MdDeleteForever } from "react-icons/md";
import { BiSolidImageAdd } from "react-icons/bi";
import CropEasy from "../../../pages/membros/crop/CropEasy";
import "../Items/items.css";
import Dialog from '@mui/material/Dialog';

function Photos({ handleCroppedImage, handleImageRemove }) {
  const [openCrop, setOpenCrop] = useState(false);
  const [isCropping, setIsCropping] = useState(false);
  const [selectedInput, setSelectedInput] = useState(null);
  const [imageData, setImageData] = useState({});

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const inputId = event.target.id;
    const maxFileSize = 20 * 1024 * 1024; // 10 MB em bytes
  
    if (file) {
      // Verifique o tamanho do arquivo
      if (file.size <= maxFileSize) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          setImageData({
            ...imageData,
            [inputId]: reader.result,
          });
          setSelectedInput(inputId);
          setIsCropping(true);
          setOpenCrop(true);
        };
      } else {
        alert('O arquivo é muito grande! Por favor, selecione um arquivo de no máximo 10 MB.');
      }
    }
  };

  const handleDeleteImage = (inputId) => {
    const updatedImageData = { ...imageData };
    delete updatedImageData[inputId];
    setImageData(updatedImageData);
  
    // Limpa o input do arquivo selecionado
    if (document.getElementById(inputId)) {
      document.getElementById(inputId).value = "";
    }
  
    setIsCropping(false);
    setOpenCrop(false);
    handleImageRemove('ph1');
  };

  const handlePhotoEmptyClick = (inputId) => {
    if (inputId !== "ph1" && !imageData[`ph${inputId[inputId.length - 1] - 1}`]) {
      return;
    }

    document.getElementById(inputId).click();
  };

  const handleImageCrop = (inputId, croppedImage) => {
    setImageData({ ...imageData, [inputId]: croppedImage });
    handleCroppedImage(inputId, croppedImage);
    setIsCropping(false);
  };


  

  const renderImage = (inputId, altText) => {
    return (
      <div id={`phC${inputId}`} className={`photoX ${!imageData[inputId] ? "ph" : ""}`}>
        <img className="photoX" src={imageData[inputId] || ''} alt={altText} />
        <div className="bgIX">
          <MdDeleteForever id={`del${inputId}`} className="iconX" onClick={() => handleDeleteImage(inputId)} />
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="alRowF">
          {/* Photo 1 */}
        <div id="phE1" className={`photoEmpty ${imageData["ph1"] ? "ph" : ""}`} onClick={() => document.getElementById("ph1").click()}>
          <div className="col">
            <BiSolidImageAdd className="iconPhoto"/>
            Add Foto 1
          </div>
        </div>
        <input
          className="inputPhoto"
          type="file"
          id="ph1"
          name="foto1"

           accept=".jpg, .jpeg, .png, .webp" 
          onChange={handleImageUpload}
          style={{ display: "none" }}
        />
        {renderImage("ph1", "Foto 1")}

        {/* Photo 2 */}
        <div id="phE2" className={`photoEmpty ${imageData["ph2"] ? "ph" : ""} ${!imageData["ph1"] ? "inactive" : ""}`} onClick={() => handlePhotoEmptyClick("ph2")}>
          <div className="col">
          <BiSolidImageAdd className="iconPhoto"/>
            Add Foto 2
          </div>
        </div>
        <input
          className="inputPhoto"
          type="file"
          id="ph2"
          name="foto2"
          accept=".jpg, .jpeg, .png, .webp" 
          onChange={handleImageUpload}
          style={{ display: "none" }}
        />
        {renderImage("ph2", "Foto 2")}

        {/* Photo 3 */}
        <div id="phE3" className={`photoEmpty ${imageData["ph3"] ? "ph" : ""} ${!imageData["ph2"] ? "inactive" : ""}`} onClick={() => handlePhotoEmptyClick("ph3")}>
          <div className="col">
          <BiSolidImageAdd className="iconPhoto"/>
            Add Foto 3
          </div>
        </div>
        <input
          className="inputPhoto"
          type="file"
          id="ph3"
          name="foto3"
          accept=".jpg, .jpeg, .png, .webp" 
          onChange={handleImageUpload}
          style={{ display: "none" }}
        />
        {renderImage("ph3", "Foto 3")}

        {/* Photo 4 */}
        <div id="phE4" className={`photoEmpty ${imageData["ph4"] ? "ph" : ""} ${!imageData["ph3"] ? "inactive" : ""}`} onClick={() => handlePhotoEmptyClick("ph4")}>
          <div className="col">
          <BiSolidImageAdd className="iconPhoto"/>
            Add Foto 4
          </div>
        </div>
        <input
          className="inputPhoto"
          type="file"
          id="ph4"
          name="foto4"
          accept=".jpg, .jpeg, .png, .webp" 
          onChange={handleImageUpload}
          style={{ display: "none" }}
        />
        {renderImage("ph4", "Foto 4")}
      </div>

      <Dialog open={openCrop} onClose={() => {setIsCropping(false); setOpenCrop(false);}} disableBackdropClick={true}>
        <CropEasy
          photoURL={imageData[selectedInput]}
          setOpenCrop={setOpenCrop}
          setCroppedPhotoURL={(url) => handleImageCrop(selectedInput, url)}
          handleDeleteImage={handleDeleteImage}  // passando como prop
          selectedInput={selectedInput}
        />
      </Dialog>
    </div>
  );
}

export default Photos;
