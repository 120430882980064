import { BsLink45Deg, BsQrCode, BsInfinity, BsFillKeyFill } from "react-icons/bs";
import { HiColorSwatch } from "react-icons/hi";
import { FaRankingStar } from "react-icons/fa6";
import { BiSupport } from "react-icons/bi";


function AssOfertaAnual({setComponenteAtivo, plano, email}){

    function abrirLink() {
        window.open('https://pay.kiwify.com.br/zsBGQY5', '_blank');
      }

    return(
        <div className="ass-modoAnual">
                    <div className="ass--modo">
                        <div onClick={() => setComponenteAtivo("mensal")}>Mensal</div>

                        <div className="toggleA" onClick={() => setComponenteAtivo("mensal")}>
                            <div className="toggleA--ballA"></div>
                        </div>

                        <div style={{display:'flex', fontWeight:700, fontSize:'24px', flexDirection:'row', alignItems:'center', gap:'4px', color:'#FEEC6E'}}>Anual<span style={{fontSize:'12px', color:'#94FF94'}}>-29%</span></div>
                    
                    </div>

                    <div className="ass--itens">
                            <div className="ass--item"><BsLink45Deg style={{marginLeft:'64px', fontSize:'20px', color:'#FEEC6E'}}/>Link exclusivo</div>
                            <div className="ass--item"><BsQrCode style={{marginLeft:'64px', fontSize:'20px', color:'#FEEC6E'}}/>QR-Code</div>
                            <div className="ass--item"><HiColorSwatch style={{marginLeft:'64px', fontSize:'20px', color:'#FEEC6E'}}/>Catálogo personalizado</div>
                            <div className="ass--item"><FaRankingStar style={{marginLeft:'64px', fontSize:'20px', color:'#FEEC6E'}}/>Seção TOP7</div>
                            <div className="ass--item"><BsInfinity style={{marginLeft:'64px', fontSize:'20px', color:'#FEEC6E'}}/>Tattos ilimitadas</div>
                            <div className="ass--item"><BiSupport style={{marginLeft:'64px', fontSize:'20px', color:'#FEEC6E'}}/>Suporte exclusivo</div>
                            <div className="ass--item" style={{fontWeight:700}}><BsFillKeyFill style={{marginLeft:'64px', fontSize:'20px', color:'#FEEC6E'}}/>ACESSO POR 12 MESES</div>

                    </div>

                    <div style={{display:'flex', flexDirection:'row', marginLeft:'24px'}}>De<span style={{marginLeft:'4px', textDecoration:'line-through', fontWeight:700, color:'#FEEC6E'}}>R$1.198,80</span> </div>

                    <div className="ass--preco">
                        <div style={{fontSize:'29px'}}>R$</div> 
                        <div style={{fontSize:'124px', lineHeight:0.85, fontWeight:700, position:'relative' }}>848
                        <div className="ass--anual" >à vista<br/>no Pix</div></div> 
                        <div style={{fontSize:'16px', textAlign:'left'}}>no <br/>1º ano</div>   
                    </div>

                    <div style={{width:'100%', display:'flex', justifyContent:'center'}}>ou até 12x de R$85,14<br/>no Cartão de Crédito</div>
                    <div style={{width:'100%', display:'flex', justifyContent:'center', fontSize:'10px', marginTop:'8px', color:'rgba(255,255,255,0.5)'}}>*A partir do 2º ano, o valor anual volta a ser R$948 à vista</div>

                    {plano !== 'yearly' ? (<div className="ass--botaoCTA" onClick={abrirLink}>Quero o Plano Anual</div>)
        :(<div className="ass--botaoPatual">Seu Plano Atual</div>)}
                    <div style={{fontSize:'12px', color:'#94FF94'}}> Compra segura pela Kiwify</div>

                </div>
    )
    
}


export default AssOfertaAnual;