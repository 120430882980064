import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import "./register.css";
import "../../App.css";
import { AiFillEyeInvisible, AiFillEye, AiFillCloseCircle, AiFillCheckCircle } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import { useCreateUserWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { auth, db } from "../../services/firebaseConfig";
import { useLocation } from "react-router-dom";
import { query, where, getDocs, collection, doc, setDoc, getDoc, onSnapshot, updateDoc } from 'firebase/firestore';
import ProgressoSaveConta from "../../components/C_Body/Items/saveProgressCount";


function Register2() {
   
    const navigate = useNavigate();
    const [showProgress, setShowProgress] = useState(false);
    const [progress, setProgress] = useState(0);
    const location = useLocation();
    const invitationId = location.search.substring(1);
    

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                console.log("Usuário logado: ", user);
                // Você pode acessar várias propriedades do usuário como user.email, user.uid, etc.
            } else {
                console.log("Nenhum usuário logado");
            }
        });

        
    
        // Não se esqueça de cancelar a inscrição para evitar vazamentos de memória
        return () => unsubscribe();
    }, []);

   


    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showPasswordAlert, setShowPasswordAlert] = useState(false);
    const [showPasswordAlert2, setShowPasswordAlert2] = useState(false);
    const [showEmailAlert, setShowEmailAlert] = useState(false);

    const [
        createUserWithEmailAndPassword,
        user,
        loading,
        error,
    ] = useCreateUserWithEmailAndPassword(auth);

    // Função para alternar a visibilidade das senhas
    const togglePasswordVisibility = (type) => {
        if (type === 'password') {
            setShowPassword(!showPassword);
        } else {
            setShowConfirmPassword(!showConfirmPassword);
        }
    };

    const handleSignUp = async (e) => {
        e.preventDefault();

         // Novo: Verificação para garantir que a senha tem pelo menos 8 caracteres
    if (password.length < 8 || confirmPassword.length < 8) {
        setShowPasswordAlert2(true);
        setTimeout(() => setShowPasswordAlert2(false), 5000);
        return;
    }

    // Ação 1: Verifique se as senhas são iguais
    if (password !== confirmPassword) {
        setShowPasswordAlert(true);
        setTimeout(() => setShowPasswordAlert(false), 5000);
        return;
    }

        // Ação 2: Verificar se o email já está registrado
        try {
            const q = query(collection(db, 'user'), where('email', '==', email));
            const querySnapshot = await getDocs(q);
    
            if (!querySnapshot.empty) {
                setShowEmailAlert(true);
                setTimeout(() => setShowEmailAlert(false), 5000);
                return;
            }
        } catch (err) {
            console.error("Erro ao verificar o email:", err);
        }

        // Ação 3: Registrar o usuário
        try {
            setShowProgress(true); 


            const userCredential = await createUserWithEmailAndPassword(email, password);
            const { user } = userCredential;
            
            // Cria um novo documento na coleção 'user'
            if (user) {
                setProgress(10);
                const currentTime = new Date(); 

                await setDoc(doc(db, 'user', user.uid), {
                    email: user.email,
                    uid: user.uid,
                    created_time: currentTime,
                    pos: false
                });
                setTimeout(() => {
                    setProgress(70);
                }, 2000);
                
              
            setProgress(100);
            setTimeout(() => {
                navigate('/pos');
            }, 2000);
            }
        } catch (err) {
            console.error("Erro ao criar o usuário:", err);
            console.error("Detalhes do erro:", err.response ? err.response.data : 'Sem detalhes disponíveis');
        }finally {
             // Esconder o componente ProgressoSaveConta após a conclusão da Ação 3
        }
    };

    

    return (

        <div className="registerP">
            <div style={{position:'absolute', bottom:'8px', color:'rgba(255,255,255,0.3)', fontSize:'12px'}}>© 2023 Ktalus - Todos os direitos reservados</div>
{showProgress && <ProgressoSaveConta progress={progress} />}
            <div className="r--card">
                <div className='r--header'>
                    <img style={{ width: "155px"}}
                    src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2FKtalus%20Logo%20H.png?alt=media&token=6ded890b-555f-41b2-8bfb-fad7700339df&_gl=1*wr9qza*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NzY0MjI4Ny4yNTcuMS4xNjk3NjQyMzIwLjI3LjAuMA.."
                    alt="Ktalus"/>
                    <h1 className="h1sub">O <span style={{ fontWeight:'700', margin:'0px 4px'}}>Catálogo Profissional de Respeito</span> que suas<span style={{color:'#FEEC6E', fontWeight:'700', margin:'0px 4px'}}>Tattoos</span> merecem...</h1>
                 </div>
                

            <div className="r--form">
                
                <div className='form--input'>
                    <div className='label'>Email</div>
                    <input  id="email" type="email"  name="email"
                        placeholder="Seu email"  onChange={(e) => setEmail(e.target.value)}
                    />
                    </div>

                    {showEmailAlert && <div className="alert">Já existe registro com este email!</div>}
                    <div className='form--input'>
                    <div className='label'>Senha</div>
                        <div className="senha">
                            <input
                                id="password"
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                autoComplete="off"
                                placeholder="********"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <AiFillEyeInvisible
                                className='view'
                                style={{ display: showPassword ? 'none' : 'block' }}
                                onClick={() => togglePasswordVisibility('password')}
                            />
                            <AiFillEye
                                className='view'
                                style={{ display: showPassword ? 'block' : 'none' }}
                                onClick={() => togglePasswordVisibility('password')}
                            />
                            
                        </div> 
                        <div className="infoSenha">min. 8 caracteres</div>
                        </div>
                        {showPasswordAlert2 && <div className="alert">A senha precisa conter pelo menos 8 caracteres!</div>}
                       
                        <div className='form--input'>
                        <div className='label'>Confirmar senha</div>
                        <div className="senha">
                            <input
                                id="ConfirmPassword"
                                type={showConfirmPassword ? 'text' : 'password'}
                                name="confirmPassword"
                                autoComplete="off"
                                placeholder="********"
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                            <AiFillEyeInvisible
                                className='view'
                                style={{ display: showConfirmPassword ? 'none' : 'block' }}
                                onClick={() => togglePasswordVisibility('confirmPassword')}
                            />
                            <AiFillEye
                                className='view'
                                style={{ display: showConfirmPassword ? 'block' : 'none' }}
                                onClick={() => togglePasswordVisibility('confirmPassword')}
                            />
                        </div>

                        </div>
                        {showPasswordAlert && <div className="alert">Senhas não estão iguais!</div>}
                        
                        <div className="TUPP">Ao clicar em 'Criar conta' você afirma que leu e concorda com os <a href="https://ktalusink.pro/kter" target="_blank" rel="noopener noreferrer" style={{color:'#FEEC6E', fontSize:'12px',fontWeight:700, textDecoration:'underline'}}>Termos de uso</a> e <a href="https://ktalusink.pro/kpol" target="_blank" rel="noopener noreferrer" style={{color:'#FEEC6E', fontSize:'12px',fontWeight:700, textDecoration:'underline'}}>Política de privacidade</a></div>
                        <button type="button" className="login" onClick={handleSignUp}>
                            INICIAR TESTE DE 7 DIAS
                        </button>
                   
                </div>
            </div>
        </div>)
   
}

export default Register2;
