import React, { useState, useEffect } from 'react';
import { GiAlliedStar } from "react-icons/gi";
import { FaCircleCheck } from "react-icons/fa6";
import { collection, query, where, onSnapshot, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../services/firebaseConfig';
import './items.css';

function DefinirTop({ eID, onClose, topNumber }) {
  const [tattoos, setTattoos] = useState([]);
  const [selectedTattooId, setSelectedTattooId] = useState(null); // Novo estado para armazenar o ID do documento selecionado


  useEffect(() => {
    const q = query(collection(db, 'tattoos'), where('EstudioID', '==', eID), where('top', '==', ''));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const tattoosData = [];
      querySnapshot.forEach((doc) => {
        tattoosData.push({ id: doc.id, ...doc.data() });
      });
      setTattoos(tattoosData);
    });

    return () => unsubscribe();
  }, [eID]);

  const handleSelect = (id) => {
    setSelectedTattooId(id);
  };

  const handleSave = async () => {
    if (selectedTattooId) {
      const tattooDocRef = doc(db, 'tattoos', selectedTattooId);
      await updateDoc(tattooDocRef, {
        top: topNumber
      });
      onClose();
    }
  };



  return (
    <div className="bgAcao">
      <div className="cardAddSecao" onClick={(e) => e.stopPropagation()}>
        <div className="nameAction">
          <GiAlliedStar className="iconCat" />
          Definir TOP {topNumber}
        </div>

        <div className="contCard">
          <div className="results">
            {tattoos.map((tattoo) => (
              <div className="cardTopSet2" key={tattoo.id} onClick={() => handleSelect(tattoo.id)}>
                <img className="capaTop1" src={tattoo.img1 || ''} alt="" />
                <div className="selectTop" style={{ display: selectedTattooId === tattoo.id ? 'block' : 'none' }}>
                  <FaCircleCheck style={{ fontSize: '32px', marginTop: '32px' }} />
                </div>
              </div>
            ))}
          </div>

          <div className="botoesCard">
            <button className="brCardCancel" onClick={() => onClose()}>
              Cancelar
            </button>
            <button className="btCardConfirm" onClick={() => handleSave()} disabled={!selectedTattooId}>
            
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DefinirTop;
