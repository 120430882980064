import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import Perso1 from './Perso1';
import Perso2 from './Perso2';

function Personalizacao() {
  // Estado para os dados do formulário
  const [formData, setFormData] = useState({
    cor: '', 
    fonte: '', 
    estilo: ''
  });

  // Estado para a URL da foto cortada
  const [globalCroppedPhotoURL, setGlobalCroppedPhotoURL] = useState(null);

  // Estado para alternar entre Perso1 e Perso2
  const [showPosP1, setShowPosP1] = useState(true);

  // Estado para armazenar os dados do estúdio do Firestore
  const [estudioData, setEstudioData] = useState(null);

  useEffect(() => {
    // Pegando a autenticação
    const auth = getAuth();
    // Escutando mudanças na autenticação
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const userIDLogado = user.uid;
        
        // Inicializando o Firestore
        const db = getFirestore();

        // Buscando no Firestore
        const q = query(collection(db, 'estudio'), where('userID', '==', userIDLogado));
        getDocs(q).then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // Atualizando o estado com os dados do estúdio
            setEstudioData(doc.data());
          });
        }).catch((error) => {
          console.log(`Erro ao buscar o documento: ${error}`);
        });
      }
    });
  }, []);

  // Função para avançar para Perso2
  const handleNext = () => {
    setShowPosP1(false);
  };

  // Função para voltar para Perso1
  const handleBack = () => {
    setShowPosP1(true);
  };

  return (
    <div className='page'>
      {/* Verificando se o estudioData está disponível antes de acessar suas propriedades */}
      
      {showPosP1 
        ? <Perso1 onNext={handleNext} formData={formData} setFormData={setFormData}  perfil={estudioData ? estudioData.perfil : null} nome={estudioData ? estudioData.nomeEstudio : null}/>
        : <Perso2 onBack={handleBack}  
                  initialCroppedPhotoURL={globalCroppedPhotoURL}  
                  setGlobalCroppedPhotoURL={setGlobalCroppedPhotoURL}  
                  formData={formData} 
                  setFormData={setFormData} perfil={estudioData ? estudioData.perfil : null} 
                  nome={estudioData ? estudioData.nomeEstudio : null}
          />
      }
    </div>
  );
}

export default Personalizacao;
