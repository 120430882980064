import React, { useEffect, useState, useRef } from 'react';
import { BsFileImage } from "react-icons/bs";
import { AiFillCloseSquare } from "react-icons/ai";
import DefinirCrs from './P04DefinirCrs';
import { db } from '../../services/firebaseConfig';
import { updateDoc, getDocs, query, where, getFirestore, collection, doc, } from "firebase/firestore";


function P04Carrossel ({estudio, nome, setComponenteAtivo, cImg1, cImg2, cImg3, cImg4, cImg5, cImg6, cImg7}){
    const [showDefinirCrs, setShowDefinirCrs] = useState({show: false, id: null});
    const handleShowDefinirCrs = (id) => {
        setShowDefinirCrs({show: true, id});
      };

    const updateImgBioField = async (estudioId, field) => {
        try {
          const db = getFirestore();
          const estudioRef = doc(db, "estudio", estudioId);
          
          await updateDoc(estudioRef, {
            [field]: ""
          });
      
          console.log("Campo atualizado com sucesso");
        } catch (error) {
          console.error("Erro ao atualizar o campo: ", error);
        }
      };


    return(
<div className='pgInicialPage--Carrossel'>
                Carrossel
                <div className='carrossel--container'>

                    {!cImg1 ? (<div id='CrsE1' className='carrossel--itemEmpty'onClick={() => handleShowDefinirCrs('1')}>
                        <BsFileImage style={{fontSize:'24px'}}/>Definir Tattoo 1
                    </div>)
                    :
                    (<div id='Crs1' className='carrossel--item'>
                        <img className='CRSImg'src={cImg1} alt=''/>
                        <AiFillCloseSquare className='removeCRS' onClick={() => updateImgBioField(estudio, 'imgBio1')} />
                    </div>)}


                    {!cImg2 ? (<div id='CrsE2' className='carrossel--itemEmpty'onClick={() => handleShowDefinirCrs('2')}>
                        <BsFileImage style={{fontSize:'24px'}}/>Definir Tattoo 2
                    </div>)

                    :(<div id='Crs2' className='carrossel--item'>
                        <img className='CRSImg'src={cImg2} alt=''/>
                        <AiFillCloseSquare className='removeCRS' onClick={() => updateImgBioField(estudio, 'imgBio2')} />
                    </div>)}


                    {!cImg3 ?(<div id='CrsE3' className='carrossel--itemEmpty'onClick={() => handleShowDefinirCrs('3')}>
                        <BsFileImage style={{fontSize:'24px'}}/>Definir Tattoo 3
                    </div>)
                    :
                    (<div id='Crs3' className='carrossel--item'>
                        <img className='CRSImg'src={cImg3} alt=''/>
                        <AiFillCloseSquare className='removeCRS' onClick={() => updateImgBioField(estudio, 'imgBio3')} />
                    </div>)}


                    {!cImg4 ? (<div id='CrsE4' className='carrossel--itemEmpty' onClick={() => handleShowDefinirCrs('4')}>
                        <BsFileImage style={{fontSize:'24px'}}/>Definir Tattoo 4
                    </div>):

                    (<div id='Crs4' className='carrossel--item'>
                        <img className='CRSImg'src={cImg4} alt=''/>
                        <AiFillCloseSquare className='removeCRS' onClick={() => updateImgBioField(estudio, 'imgBio4')} />
                    </div>)}


                    {!cImg5 ? (<div id='CrsE5' className='carrossel--itemEmpty'onClick={() => handleShowDefinirCrs('5')}>
                        <BsFileImage style={{fontSize:'24px'}}/>Definir Tattoo 5
                    </div>):

                    (<div id='Crs5' className='carrossel--item'>
                        <img className='CRSImg'src={cImg5} alt=''/>
                        <AiFillCloseSquare className='removeCRS' onClick={() => updateImgBioField(estudio, 'imgBio5')} />
                    </div>)}


                    {!cImg6 ? (<div id='CrsE6' className='carrossel--itemEmpty' onClick={() => handleShowDefinirCrs('6')}>
                        <BsFileImage style={{fontSize:'24px'}}/>Definir Tattoo 6
                    </div>):

                    (<div id='Crs6' className='carrossel--item'>
                        <img className='CRSImg'src={cImg6} alt=''/>
                        <AiFillCloseSquare className='removeCRS' onClick={() => updateImgBioField(estudio, 'imgBio6')} />
                    </div>)}


                    {!cImg7 ? (<div id='CrsE7' className='carrossel--itemEmpty' onClick={() => handleShowDefinirCrs('7')}>
                        <BsFileImage style={{fontSize:'24px'}}/>Definir Tattoo 7
                    </div>):

                    (<div id='Crs7' className='carrossel--item'>
                        <img className='CRSImg'src={cImg7} alt=''/>
                        <AiFillCloseSquare className='removeCRS' onClick={() => updateImgBioField(estudio, 'imgBio7')} />
                    </div>)}

                    {showDefinirCrs.show && <DefinirCrs id={showDefinirCrs.id} eID={estudio} onClose={() => setShowDefinirCrs(false)}/>}

                </div>
            </div>
    )


}

export default P04Carrossel;