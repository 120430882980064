import React, { useEffect, useState } from 'react';
import HeaderPage from "../../components/C_Body/HeaderPage";
import { BiEdit } from "react-icons/bi";
import { FaCircleCheck } from "react-icons/fa6";
import { getImageURL } from '../register/GlifosImg'; 
import { doc, updateDoc } from "firebase/firestore"; 
import { db } from '../../services/firebaseConfig';

function P05Personalizar({  estudio, setComponenteAtivo, tema, fonte, estilo, nome, perfil }){

    const [corState, setCorState] = useState(tema);
    const [fonteState, setFonteState] = useState(fonte);
    const [estiloState, setEstiloState] = useState(estilo);

    const [editCor, setEditCor] = useState(false);
    const [editFonte, setEditFonte] = useState(false);
    const [editEstilo, setEditEstilo] = useState(false);

    const fontPage = {
        roboto: 'fontRoboto', slab: 'fontSlab', serif: 'fontSerif',
      };
    
      const colorPage = {
        vermelho:'pagePadrao', laranja:'pagePadrao', amarelo:'pagePadrao', verde:'pagePadrao', ciano:'pagePadrao',
        azul:'pagePadrao', roxo:'pagePadrao', rosa:'pagePadrao', magenta:'pagePadrao', branco:'pagePadrao',
    
        goldVermelho: 'pageVer', goldVerde:'pageVerd', goldAzul:'pageAzu', goldRoxo:'pageRox', goldAmarelo:'pageAma',
    
        silverVermelho: 'pageVer', silverVerde:'pageVerd', silverAzul:'pageAzu', silverRoxo:'pageRox', silverAmarelo:'pageAma',
    
        vermelhoVerde:'pageVerd', laranjaCiano:'pageCia', verdeVermelho:'pageVer', azulAmarelo:'pageAma',
        
        
      };

      const colorPage2 = {
        vermelho:'pagePadrao bVer', laranja:'pagePadrao bLan', amarelo:'pagePadrao bAma', verde:'pagePadrao bVerd', ciano:'pagePadrao bCia',
        azul:'pagePadrao bAzu', roxo:'pagePadrao bRox', rosa:'pagePadrao bRos', magenta:'pagePadrao bMag', branco:'pagePadrao bBra',
    
        goldVermelho: 'pageVer bAma', goldVerde:'pageVerd bAma', goldAzul:'pageAzu bAma', goldRoxo:'pageRox bAma', goldAmarelo:'pageAma bAma',
    
        silverVermelho: 'pageVer bBra', silverVerde:'pageVerd bBra', silverAzul:'pageAzu bBra', silverRoxo:'pageRox bBra', silverAmarelo:'pageAma bBra',
    
        vermelhoVerde:'pageVerd bVer', laranjaCiano:'pageCia bLan', verdeVermelho:'pageVer bVerd', azulAmarelo:'pageAma bAzu',
        
        
      };
    
      const colorBorder = {
        vermelho:'bVer', laranja:'bLan', amarelo:'bAma', verde:'bVerd', ciano:'bCia',
        azul:'bAzu', roxo:'bRox', rosa:'bRos', magenta:'bMag', branco:'bBra',
    
        goldVermelho: 'bAma', goldVerde:'bAma', goldAzul:'bAma', goldRoxo:'bAma', goldAmarelo:'bAma',
    
        silverVermelho: 'bBra', silverVerde:'bBra', silverAzul:'bBra', silverRoxo:'bBra', silverAmarelo:'bBra',
    
        vermelhoVerde:'bVer', laranjaCiano:'bLan', verdeVermelho:'bVerd', azulAmarelo:'bAzu',
        
        
      };

      const estiloK = {
        estiloN:'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Festilos%2F01%20none.png?alt=media&token=8f2ae227-56e9-480e-ada2-16274cde4446&_gl=1*14hlihh*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4MDkxOC4xOTUuMS4xNjk2MjgyMjAwLjYwLjAuMA..',
        estilo1:'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Festilos%2F02%20style.png?alt=media&token=90394ec6-f223-4f9e-8338-e949d3d8b792&_gl=1*xwdd7o*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4MDkxOC4xOTUuMS4xNjk2MjgyMjQ1LjE1LjAuMA..',
        estilo2:'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Festilos%2F03%20style.png?alt=media&token=1b594710-1ede-436c-9b71-75e3fc232217&_gl=1*k0sil4*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4MDkxOC4xOTUuMS4xNjk2MjgyNDMwLjYwLjAuMA..',
        estilo3:'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Festilos%2F04%20style.png?alt=media&token=7121066f-98de-4a58-a5c4-27ce4ae7c108&_gl=1*4b0kjh*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4MDkxOC4xOTUuMS4xNjk2MjgyNDQxLjQ5LjAuMA..'
      }
    
      const atualizarTemaFirestore = async () => {
        try {
            const estudioRef = doc(db, "estudio", estudio); // Referência para o documento
            await updateDoc(estudioRef, {
                tema: corState,  // Supondo que temaState contém o novo valor para o campo 'tema'
            });
            
            console.log("Documento atualizado com sucesso!");
            
            // Seta o estado 'editCor' como false após atualizar o documento com sucesso
            setEditCor(false);
        } catch (error) {
            console.error("Erro atualizando documento: ", error);
        }
    };

    const atualizarFonteFirestore = async () => {
        try {
            const estudioRef = doc(db, "estudio", estudio); // Referência para o documento
            await updateDoc(estudioRef, {
                fonte: fonteState,  // Supondo que temaState contém o novo valor para o campo 'tema'
            });
            
            console.log("Documento atualizado com sucesso!");
            
            // Seta o estado 'editCor' como false após atualizar o documento com sucesso
            setEditFonte(false);
        } catch (error) {
            console.error("Erro atualizando documento: ", error);
        }
    };

    const atualizarEstiloFirestore = async () => {
        try {
            const estudioRef = doc(db, "estudio", estudio); // Referência para o documento
            await updateDoc(estudioRef, {
                estilo: estiloState,  // Supondo que temaState contém o novo valor para o campo 'tema'
            });
            
            console.log("Documento atualizado com sucesso!");
            
            // Seta o estado 'editCor' como false após atualizar o documento com sucesso
            setEditEstilo(false);
        } catch (error) {
            console.error("Erro atualizando documento: ", error);
        }
    };
    
      
      


    return(
        <div className='personalizarPage'>
            <HeaderPage pg={'Personalizar'} setComponenteAtivo={setComponenteAtivo} />
            <div className='personalizarPage--container'>

                <div className='personalizar--previewcontainer'>
                    Preview
                    <div className='previewcontainer'>
                        <div  className={`previewPerfilPerso ${corState ? colorPage[corState] || '' : ''}`}>
                            <div  className={`previewPerfilb ${corState ? colorBorder[corState] || '' : ''}`}></div>
                            
                            <img className='Prev--cantoD' style={{ display: estiloState === 'estiloN' ? 'none' : 'flex' }}
                            src={getImageURL(estiloState, corState)} alt='' />

                            <img className='Prev--cantoC' style={{ display: estiloState === 'estiloN' ? 'none' : 'flex' }}
                            src={getImageURL(estiloState, corState)} alt='' />

                            <img className='Prev--cantoB' style={{ display: estiloState === 'estiloN' ? 'none' : 'flex' }}
                            src={getImageURL(estiloState, corState)} alt=''/>

                            <img className='Prev--cantoA' style={{ display: estiloState === 'estiloN' ? 'none' : 'flex' }}
                            src={getImageURL(estiloState, corState)} alt=''/>




                            <div  className={`prepreviewPerfil-borda ${corState ? colorBorder[corState] || '' : ''}`} >
                                <img className="prepreviewPerfil-foto" src={perfil} alt="" background="#fff"/>
                            </div>
                            {nome}
                            </div>

                    </div>
                </div>

                <div className='personalizar--corcontainer'>
                        <div className='descricaotittle'>
                            Cor
                            {!editCor &&<div className='nomeContainer--buttonEdit' onClick={()=> setEditCor(true)}><BiEdit/></div>}
                        </div> 

                        {!editCor && <div className={`personalizar--cor ${corState ? colorPage2[corState] || '' : ''}`}></div>}
                       
                    {editCor && <div className='persoBoxEdit'>

                    <div className="cores" style={{fontWeight:400}}>
                  
                        <div className="cores--estilo">
                        Padrão
                            <div className="cores--cores">
                                
                                <div id="vermelho" onClick={()=>setCorState('vermelho')}  style={{border: '2px solid #FF0000' ,background:'#191919',color:'#FFf'}} className="Color">{corState === 'vermelho' && <FaCircleCheck />}</div>
                                <div id="laranja" onClick={()=>setCorState("laranja")}  style={{border: '2px solid #FF8000' ,background:'#191919',color:'#FFf'}} className="Color">{corState === "laranja" && <FaCircleCheck />}</div>
                                <div id="amarelo" onClick={()=>setCorState("amarelo")}  style={{border: '2px solid #FFD400' ,background:'#191919',color:'#FFf'}} className="Color">{corState === "amarelo" && <FaCircleCheck />}</div>
                                <div id="verde" onClick={()=>setCorState("verde")}  style={{border: '2px solid #00FF00' ,background:'#191919',color:'#FFf'}} className="Color">{corState === "verde" && <FaCircleCheck />}</div>
                                <div id="ciano" onClick={()=>setCorState("ciano")}  style={{border: '2px solid #00FFFF' ,background:'#191919',color:'#FFf'}} className="Color">{corState === "ciano" && <FaCircleCheck />}</div>
                                <div id="azul" onClick={()=>setCorState("azul")}  style={{border: '2px solid #0080FF' ,background:'#191919',color:'#FFf'}} className="Color">{corState === "azul" && <FaCircleCheck />}</div>
                                <div id="roxo" onClick={()=>setCorState("roxo")}  style={{border: '2px solid #AA00FF' ,background:'#191919',color:'#FFf'}} className="Color">{corState === "roxo" && <FaCircleCheck />}</div>
                                <div id="rosa" onClick={()=>setCorState("rosa")}  style={{border: '2px solid #FF00D4' ,background:'#191919',color:'#FFf'}} className="Color">{corState === "rosa" && <FaCircleCheck />}</div>
                                <div id="magenta"  onClick={()=>setCorState("magenta")} style={{border: '2px solid #FF0055' ,background:'#191919',color:'#FFf'}} className="Color">{corState === "magenta" && <FaCircleCheck />}</div>
                                <div id="branco" onClick={()=>setCorState("branco")}  style={{border: '2px solid #CCCCCC' ,background:'#191919',color:'#FFf'}} className="Color">{corState === "branco" && <FaCircleCheck />}</div>
                            
                            </div>

                        </div>

                        <div className="cores--estilo">
                        Gold
                            <div className="cores--cores">
                                
                                <div id="goldVermelho" onClick={()=>setCorState("goldVermelho")} style={{border: '2px solid #FFD400' ,background:'#190000',color:'#FFf'}} className="Color">{corState === "goldVermelho" && <FaCircleCheck />}</div>
                                <div id="goldVerde" onClick={()=>setCorState("goldVerde")} style={{border: '2px solid #FFD400' ,background:'#001900',color:'#FFf'}} className="Color">{corState === "goldVerde" && <FaCircleCheck />}</div>
                                <div id="goldAzul" onClick={()=>setCorState("goldAzul")} style={{border: '2px solid #FFD400' ,background:'#000D19',color:'#FFf'}} className="Color">{corState === "goldAzul" && <FaCircleCheck />}</div>
                                <div id="goldRoxo" onClick={()=>setCorState("goldRoxo")} style={{border: '2px solid #FFD400' ,background:'#110019',color:'#FFf'}} className="Color">{corState === "goldRoxo" && <FaCircleCheck />}</div>
                                <div id="goldAmarelo" onClick={()=>setCorState("goldAmarelo")} style={{border: '2px solid #FFD400' ,background:'#191500',color:'#FFf'}} className="Color">{corState === "goldAmarelo" && <FaCircleCheck />}</div>
                            
                                

                            </div>

                        </div>

                        <div className="cores--estilo">
                        Silver
                            <div className="cores--cores">
                                
                                <div id="silverVermelho" onClick={()=>setCorState("silverVermelho")} style={{border: '2px solid #CCCCCC' ,background:'#190000',color:'#FFf'}} className="Color">{corState === "silverVermelho" && <FaCircleCheck />}</div>
                                <div id="silverVerde" onClick={()=>setCorState("silverVerde")} style={{border: '2px solid #CCCCCC' ,background:'#001900',color:'#FFf'}} className="Color">{corState === "silverVerde" && <FaCircleCheck />}</div>
                                <div id="silverAzul" onClick={()=>setCorState("silverAzul")} style={{border: '2px solid #CCCCCC' ,background:'#000D19',color:'#FFf'}} className="Color">{corState === "silverAzul" && <FaCircleCheck />}</div>
                                <div id="silverRoxo" onClick={()=>setCorState("silverRoxo")} style={{border: '2px solid #CCCCCC' ,background:'#110019',color:'#FFf'}} className="Color">{corState === "silverRoxo" && <FaCircleCheck />}</div>
                                <div id="silverAmarelo" onClick={()=>setCorState("silverAmarelo")} style={{border: '2px solid #CCCCCC' ,background:'#191500',color:'#FFf'}} className="Color">{corState === "silverAmarelo" && <FaCircleCheck />}</div>
                            
                            </div>

                        </div>

                        <div className="cores--estilo">
                        Complementares
                            <div className="cores--cores">
                                
                                <div id="vermelhoVerde" onClick={()=>setCorState("vermelhoVerde")} style={{border: '2px solid #FF0000' ,background:'#001900',color:'#FFf'}} className="Color">{corState === "vermelhoVerde" && <FaCircleCheck />}</div>
                                <div id="laranjaCiano" onClick={()=>setCorState("laranjaCiano")} style={{border: '2px solid #FF8000' ,background:'#001919',color:'#FFf'}} className="Color">{corState === "laranjaCiano" && <FaCircleCheck />}</div>
                                <div id="verdeVermelho" onClick={()=>setCorState("verdeVermelho")} style={{border: '2px solid #00FF00' ,background:'#190000',color:'#FFf'}} className="Color">{corState === "verdeVermelho" && <FaCircleCheck />}</div>
                                <div id="azulAmarelo" onClick={()=>setCorState("azulAmarelo")} style={{border: '2px solid #0079F2' ,background:'#191500',color:'#FFf'}} className="Color">{corState === "azulAmarelo" && <FaCircleCheck />}</div>
                            
                            </div>

                        </div>

                

                    </div>
                    <div className='buttonsEdit'>
                            <div className='cancelButtonEdit'onClick={()=> {setEditCor(false); setCorState(tema)}}>Cancelar</div>
                            <div className='saveButtonEdit' onClick={atualizarTemaFirestore}>Salvar</div>
                        </div>
                        </div>}

                </div>

                <div className='personalizar--corcontainer'>
                        <div className='descricaotittle'>
                            Fonte
                            {!editFonte &&<div className='nomeContainer--buttonEdit' onClick={()=> setEditFonte(true)}><BiEdit/></div>}
                        </div> 
                        {!editFonte &&<div className='personalizar--fonte' style={{fontFamily: fonteState === 'roboto' ? 'Roboto' : fonteState === 'slab' ? 'Roboto Slab' : 'Roboto Serif'}}>{fonteState}</div>}
                        {editFonte && <div className='persoBoxEdit'>
                        <div className="fonte">
                  
                            <div  className={`fonte--bt ${fonteState === "roboto" ? "ftActive" : ""}`} onClick={()=>setFonteState('roboto')} style={{fontFamily:"Roboto"}}>roboto</div>
                            <div  className={`fonte--bt ${fonteState === "slab" ? "ftActive" : ""}`} onClick={()=>setFonteState('slab')} style={{fontFamily:"Roboto slab"}}>slab</div>
                            <div  className={`fonte--bt ${fonteState === "serif" ? "ftActive" : ""}`} onClick={()=>setFonteState('serif')} style={{fontFamily:"Roboto serif"}}>serif</div>
                  
                        </div>

                        <div className='buttonsEdit'>
                            <div className='cancelButtonEdit'onClick={()=> {setEditFonte(false); setFonteState(fonte)}}>Cancelar</div>
                            <div className='saveButtonEdit' onClick={atualizarFonteFirestore}>Salvar</div>
                        </div>
                        </div>}

                </div>

                <div className='personalizar--corcontainer'>
                        <div className='descricaotittle'>
                            Estilo
                            {!editEstilo &&<div className='nomeContainer--buttonEdit'onClick={()=> setEditEstilo(true)}><BiEdit/></div>}
                        </div> 
                        {!editEstilo && <img className='personalizar--estilo' src={estiloState ? estiloK[estiloState] || '' : ''} />}
                        {editEstilo &&<div className='persoBoxEdit'>
                        <div className='estilo'>

                            <div id='estiloN' onClick={()=>setEstiloState('estiloN')}  className={`estilo--container ${estiloState === "estiloN" ? "ftActive" : ""}`} >
                                <img className='estilo--img' src='https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Festilos%2F01%20none.png?alt=media&token=8f2ae227-56e9-480e-ada2-16274cde4446&_gl=1*14hlihh*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4MDkxOC4xOTUuMS4xNjk2MjgyMjAwLjYwLjAuMA..'
                                alt=''/>
                            </div>

                            <div id='estilo01' onClick={()=>setEstiloState('estilo1')}  className={`estilo--container ${estiloState === "estilo1" ? "ftActive" : ""}`}>
                                <img className='estilo--img' src='https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Festilos%2F02%20style.png?alt=media&token=90394ec6-f223-4f9e-8338-e949d3d8b792&_gl=1*xwdd7o*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4MDkxOC4xOTUuMS4xNjk2MjgyMjQ1LjE1LjAuMA..'
                                alt=''/>
                            </div>

                            <div id='estilo02' onClick={()=>setEstiloState('estilo2')}  className={`estilo--container ${estiloState === "estilo2" ? "ftActive" : ""}`}>
                                <img className='estilo--img' src='https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Festilos%2F03%20style.png?alt=media&token=1b594710-1ede-436c-9b71-75e3fc232217&_gl=1*k0sil4*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4MDkxOC4xOTUuMS4xNjk2MjgyNDMwLjYwLjAuMA..'
                                alt=''/>
                            </div>

                            <div id='estilo03' onClick={()=>setEstiloState('estilo3')}  className={`estilo--container ${estiloState === "estilo3" ? "ftActive" : ""}`}>
                                <img className='estilo--img' src='https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Festilos%2F04%20style.png?alt=media&token=7121066f-98de-4a58-a5c4-27ce4ae7c108&_gl=1*4b0kjh*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4MDkxOC4xOTUuMS4xNjk2MjgyNDQxLjQ5LjAuMA..'
                                alt=''/>
                            </div>

                        </div>
                        <div className='buttonsEdit'>
                            <div className='cancelButtonEdit'onClick={()=> {setEditEstilo(false); setEstiloState(estilo)}}>Cancelar</div>
                            <div className='saveButtonEdit'onClick={atualizarEstiloFirestore}>Salvar</div>
                        </div>
                        </div>}

                </div>


            </div>
       
        </div>
    )
}


export default P05Personalizar;



