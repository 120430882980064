import React, { useEffect, useState } from 'react';
import ClipboardJS from 'clipboard';
import HeaderPage from "../../components/C_Body/HeaderPage";
import CustomQRCode from "./QrCode";
import { HiLink } from "react-icons/hi";
import { FaRegCopy } from "react-icons/fa6";
import { AiOutlineCheckCircle } from "react-icons/ai";

function P01Link({ perfil, nick, setComponenteAtivo }) {
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        // Inicializa ClipboardJS
        const clipboard = new ClipboardJS('#copyLink', {
            text: function () {
                return `https://ktalusink.pro/${nick}`;
            }
        });

        // Evento de sucesso
        clipboard.on('success', function (e) {
            setShowAlert(true);

            setTimeout(() => {
                setShowAlert(false);
            }, 2000);
        });

        // Evento de erro
        clipboard.on('error', function (e) {
            console.error('Não foi possível copiar o texto: ', e);
        });

        return () => {
            clipboard.destroy();
        };
    }, [nick]);

    return (
        <>
            <HeaderPage pg={'Link / Qr Code'} setComponenteAtivo={setComponenteAtivo} />
            <div className="linkpage">
                <div className="linkpage--qr">
                    <div className="linkpage--qrcodeB">
                        {/* Códigos QR e outras imagens */}
                        <CustomQRCode text={`https://ktalusink.pro/${nick}`} imagePath={perfil || 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2FAnyConv.com__fotonull.webp?alt=media&token=a2f03184-202c-4db9-a1bd-a62c3236b71d'} />
                    </div>
                </div>
                <div className='linkpage--link'>
                    <HiLink style={{ fontSize: '24px', marginRight: '16px', marginLeft: '16px' }} />https://ktalusink.pro/<span className='textLink'>{nick}</span>
                </div>
                <div id='copyLink' className="btPadrao">
                    {/* Ícones e outros elementos */}
                    <FaRegCopy style={{ fontSize: '24px' }} /> Copiar link
                </div>
                {showAlert && <div className='alertaCopy'><AiOutlineCheckCircle />Link copiado</div>}
            </div>
        </>
    );
}

export default P01Link;
