import './convites.css';
import { FaTicketAlt } from "react-icons/fa";
import { BiCopy } from "react-icons/bi";
import { AiOutlineCheck } from "react-icons/ai";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../services/firebaseConfig";
import { useState, useEffect } from 'react';
import { collection, query, getDocs, orderBy, onSnapshot, addDoc, serverTimestamp } from 'firebase/firestore';

function Convites(){
    const [convites, setConvites] = useState([]);

    async function gerarConvite() {
        const novoConvite = {
            email: "", // campo de email vazio
            dataCriacao: serverTimestamp() // data e hora atuais do servidor
        };
    
        try {
            const docRef = await addDoc(collection(db, "invitation"), novoConvite);
            console.log("Convite gerado com sucesso com o ID:", docRef.id);
        } catch (error) {
            console.error("Erro ao gerar o convite:", error);
        }
    }

    useEffect(() => {
        const convitesCollection = collection(db, 'invitation');
        const q = query(convitesCollection, orderBy("dataCriacao", "desc")); // Supondo que "dataCriacao" seja o nome do seu campo de data
    
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const newConvites = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }));
    
            setConvites(newConvites);
        });
    
        return () => unsubscribe();
    }, []);

    const copiarParaClipboard = (conviteId) => {
        const textoParaCopiar = `Seu *Convite-Link* é https://ktalusink.pro/kreg?${conviteId}`;
        const textArea = document.createElement("textarea");
        textArea.value = textoParaCopiar;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("Copy");
        textArea.remove();
        alert("Link de convite copiado para a área de transferência!");
      };
      
      
       // Função para atualizar o campo 'user'
    const atualizarUsuario = async (conviteId, novoUsuario) => {
        const conviteRef = doc(db, 'invitation', conviteId);

        try {
            await updateDoc(conviteRef, {
                user: novoUsuario
            });

            console.log("Usuário atualizado com sucesso!");
        } catch (error) {
            console.error("Erro ao atualizar o usuário:", error);
        }
    };


    return(
        <div className="plataforma-bg">
            <div className="convites--container">

                <div className='convite--button' onClick={gerarConvite}>
                    <FaTicketAlt style={{fontSize:'32px'}}/>
                Gerar Convite</div>

                <div className='convite--codigos'>

                {convites.map((convite, index) => (
  <div className='codigo--container' key={convite.id}>
    <div className='ctn--codigo'>
      <div className='ctn--index'>#{index + 1}</div>
      <div className='ctn--cd' style={{ color:convite.email ? '#FEEC6E' : '#fff'}}>{convite.id}</div>
      {!convite.email ? (<div className='ctn--disp'><AiOutlineCheck/>Disponível</div>):
      (<div className='ctn--email'>{convite.email}</div>)}
      <div className='ctn--email'>{convite.user}</div>
    </div>
    <div className='ctn--user'>
    <input
                        type='text'
                        className='input--user'
                        value={convite.user || ''}
                        onChange={(e) => setConvites(prevConvites => {
                            const updatedConvites = [...prevConvites];
                            updatedConvites[index].user = e.target.value;
                            return updatedConvites;
                        })}
                    />
        <div className='save--user' onClick={() => atualizarUsuario(convite.id, convite.user)}>Salvar</div>
    </div>
    <div className='ctn--copy' onClick={() => copiarParaClipboard(convite.id)}><BiCopy/></div>
  </div>
))}



                </div>


            </div>

        </div>
    )
    
}


export default Convites;