import './PR.css';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../services/firebaseConfig';
import PosP1 from './PosP1';
import PosP2 from './PosP2';
import Perso1 from './Perso1';
import Perso2 from './Perso2';
import { doc, onSnapshot } from 'firebase/firestore'; 
import ReactPixel from 'react-facebook-pixel';

function NewEstudio() {
  const options = {
    autoConfig: true,  // Configuração automática do Pixel
    debug: false,      // Ativar modo de depuração (opcional)
  };

ReactPixel.init('370076518847979', options);
ReactPixel.pageView();

  const navigate = useNavigate();
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged(user => {
      if (user) {
        setIsUserLoggedIn(true);

        // Buscar o documento do usuário no Firestore
        const userDocRef = doc(db, 'user', user.uid);

        // Inscrever-se para atualizações em tempo real no documento do usuário
        const unsubscribeSnapshot = onSnapshot(userDocRef, (docSnap) => {
          if (docSnap.exists() && docSnap.data().pos === true) {
            navigate('/home');
          }
        });

        // Retornar uma função para cancelar as inscrições quando o componente for desmontado
        return () => {
          unsubscribeSnapshot();
          unsubscribeAuth();
        };

      } else {
        setIsUserLoggedIn(false);
        navigate('/login');
      }
    });

    // Se o usuário não estiver autenticado, não há necessidade de se inscrever para atualizações no documento do usuário
    return () => unsubscribeAuth();

  }, [navigate]);

  // Estado para os dados do formulário
  const [formData, setFormData] = useState({
    nome: '',
    ddd: '',
    celular: '',
    link: '',
    disponibilidade: null,
    cor: 'vermelho',
    fonte: 'slab',
    estilo: 'estiloN'
  });

  // Estado para a URL da foto cortada
  const [globalCroppedPhotoURL, setGlobalCroppedPhotoURL] = useState(null);

  // Estado para controle de qual componente deve ser mostrado
  const [currentStep, setCurrentStep] = useState('PosP1');

  // Função para avançar para a próxima etapa
  const handleNext = (nextStep) => {
    setCurrentStep(nextStep);
  };

  // Função para voltar para a etapa anterior
  const handleBack = (prevStep) => {
    setCurrentStep(prevStep);
  };

  return (
    <>
    {isUserLoggedIn && <div className='page '>
    
      {currentStep === 'PosP1' && <PosP1 onNext={() => handleNext('PosP2')} formData={formData} setFormData={setFormData} />}
      {currentStep === 'PosP2' && <PosP2 onNext={() => handleNext('Perso1')} onBack={() => handleBack('PosP1')} formData={formData} setFormData={setFormData} initialCroppedPhotoURL={globalCroppedPhotoURL} setGlobalCroppedPhotoURL={setGlobalCroppedPhotoURL}/>}
      {currentStep === 'Perso1' && <Perso1 onNext={() => handleNext('Perso2')} onBack={() => handleBack('PosP2')} formData={formData} setFormData={setFormData} initialCroppedPhotoURL={globalCroppedPhotoURL}/>}
      {currentStep === 'Perso2' && <Perso2 onBack={() => handleBack('Perso1')} formData={formData} setFormData={setFormData} initialCroppedPhotoURL={globalCroppedPhotoURL}/>}
    </div>}</>
  );
}

export default NewEstudio;
