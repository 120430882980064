import React, { useState, useEffect, useRef } from "react";
import { AiFillCheckCircle, AiFillCloseCircle, AiOutlineLoading3Quarters } from "react-icons/ai";
import { getFirestore, getDocs, query, where, collection } from "firebase/firestore";
import firebase from '../../services/firebaseConfig';

function PosP1({ onNext, formData, setFormData }) {
  // O estado para os valores dos campos
  const [nome, setNome] = useState(formData.nome);
  const [ddd, setDdd] = useState(formData.ddd);
  const [celular, setCelular] = useState(formData.celular);
  const [link, setLink] = useState(formData.link);
  const [disponibilidade, setDisponibilidade] = useState(formData.disponibilidade);
  const nomeRef = useRef(null);
const dddRef = useRef(null);
const celularRef = useRef(null);
const linkRef = useRef(null);

const handleKeyDown = (e, nextRef) => {
    if (e.key === 'Enter') {
      nextRef.current.focus();
      window.scrollTo(0, nextRef.current.offsetTop - 100);
    }
  };

  // Inicializa o Firestore
  const db = getFirestore(firebase);

  // Atualiza o nome no estado quando o input muda
  const handleNomeChange = (e) => {
    let valor = e.target.value;
    if (valor.length <= 25) {
        valor = valor.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
      setNome(valor);
    }
  };

  // Atualiza o DDD no estado quando o input muda
  const handleDddChange = (e) => {
    let valor = e.target.value;
    if (/^\d*$/.test(valor) && valor.length <= 2) {
      setDdd(valor);
    }
  };

  // Atualiza o celular no estado quando o input muda
  const handleCelularChange = (e) => {
    let valor = e.target.value.replace(/\D/g, '');
    if (valor.length <= 9) {
      valor = valor.replace(/^(\d{1})(\d{0,4})(\d{0,4})/, '$1 $2 $3').trim();
      setCelular(valor);
    }
  };

  

  // Verifica a disponibilidade do link quando o input muda
  useEffect(() => {
    if (link.length >= 4) {
      const timer = setTimeout(async () => {
        setDisponibilidade("verificando");
        const q = query(collection(db, "estudio"), where("nickname", "==", link));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
          setDisponibilidade("disponivel");
        } else {
          setDisponibilidade("indisponivel");
        }
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [link]);

 // Atualiza o link no estado quando o input muda
const handleLinkChange = (e) => {
    let valor = e.target.value.replace(/[^a-zA-Z0-9_]/g, '');
    valor = valor.toLowerCase();  // Converte a string para minúsculas
    setLink(valor);
  };

  const handleNextClick = () => {
    let valid = true; // Variável para verificar se todos os campos estão preenchidos corretamente

    // Função para esconder alertas após 5 segundos
    const hideAlert = (elementId) => {
        setTimeout(() => {
            document.getElementById(elementId).style.display = "none";
        }, 5000);
    }

    // Verifica se o campo nome está vazio
    if (nome === "") {
        document.getElementById("aNome").style.display = "block";
        hideAlert("aNome");
        valid = false;
    } else {
        document.getElementById("aNome").style.display = "none";
    }

    // Verifica se os campos ddd ou celular estão vazios
    if (ddd === "" || celular === "") {
        document.getElementById("aWhats").style.display = "block";
        hideAlert("aWhats");
        valid = false;
    } else {
        document.getElementById("aWhats").style.display = "none";
    }

    // Verifica se o campo link está vazio ou indisponível
    if (link === "" || disponibilidade === "indisponivel") {
        document.getElementById("aLink").style.display = "block";
        hideAlert("aLink");
        valid = false;
    } else {
        document.getElementById("aLink").style.display = "none";
    }

    // Se todos os campos estão preenchidos corretamente, execute a função onNext
    if (valid) {
        setFormData({ nome, ddd, celular, link, disponibilidade });
        onNext();
      }
};


    return(
        <div className='pr'>
            <img style={{ width: "104px"}}
                    src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2FKtalus%20Logo%20H.png?alt=media&token=6ded890b-555f-41b2-8bfb-fad7700339df&_gl=1*wr9qza*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NzY0MjI4Ny4yNTcuMS4xNjk3NjQyMzIwLjI3LjAuMA.."
                    alt="Ktalus"/>

<div className='pr--labels'>
                <label>Nome do estúdio ou profissional</label>
                <input
                    type='text'
                    name='nome'
                    placeholder='Digite aqui o nome'
                    value={nome}
                    
                    ref={nomeRef}
                    onKeyDown={(e) => handleKeyDown(e, dddRef)}
                    onChange={handleNomeChange} autocomplete="off" />
                {/* Outros elementos aqui */}

                <div id="aNome" className='alertP'>Digite o nome</div>
                <label>WhatsApp</label>
                <div className='pr--whats'>
                    <input
                        type="tel"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        name='ddd'
                        
                        placeholder='DDD'
                        style={{ width: "25%", textAlign: "center" }}
                        value={ddd}
                        ref={dddRef}
                        onKeyDown={(e) => handleKeyDown(e, celularRef)}
                        onChange={handleDddChange} autocomplete="off" />
                    <input
                        type="tel"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        name='celular'
                        
                        placeholder='9 XXXX XXXX'
                        style={{ width: "70%", textAlign: "center" }}
                        value={celular}
                        ref={celularRef}
                        onKeyDown={(e) => handleKeyDown(e, linkRef)}
                        onChange={handleCelularChange} autocomplete="off" />
                </div>
                <div id="aWhats" className='alertP'>Digite seu WhatsApp</div>
                    
                    <label>Endereço de link</label>
                    
                    <p className='pr--link'>ktls-ink.pro/<span className='pr--linkNome'>{link}</span></p>
                    <div className='pr--verif'>
        <input className='pr--verifLink'  type="text" name='link' ref={linkRef} value={link} onChange={handleLinkChange} autocomplete="off"  />
        { link.length >= 4 ? (
  <>
    { disponibilidade === "verificando" && <AiOutlineLoading3Quarters className="verificando"/> }
    { disponibilidade === "disponivel" && <AiFillCheckCircle className="disponivel"/> }
    { disponibilidade === "indisponivel" && <AiFillCloseCircle className="indisponivel"/> }
  </>
) : null }
      </div>

                    <div id="aLink" className='alertP'>Escolha um endereço válido</div>


                    

                    

                
            </div>

            <div className='pr--buttons'>
                <button className='pr--back' style={{visibility:"hidden"}} >Voltar</button>
                <button className='pr--next' onClick={handleNextClick}>Avançar</button>

            </div>
            

        </div>
    )
}


export default PosP1;