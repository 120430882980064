import { Helmet } from 'react-helmet';
import './App.css';
//import Header from './components/Header';
//import Body from './components/C_Body/Body'; 
//<Header/>
//<Body className="body"/>

//import Login from './pages/login/LoginPage';
//import Register from './pages/register/register';
import { AppRoutes } from './routes/AppRoutes';


function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Ktalus Ink</title>
        <meta property="og:title" content="Ktalus Ink" />
        {/*<meta property="og:description" content="O catálogo das Tattoos" />*/}
        <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2FKtalusBlack.svg?alt=media&token=17878113-58be-4e5f-9f0d-0f75aaf7ec44&_gl=1*1tkd9rw*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5Nzc0NzQ5Ni4yNjkuMS4xNjk3NzQ3NTAzLjUzLjAuMA.." />
        
      </Helmet>
      <AppRoutes/>
    </div>
  );
}

export default App;
