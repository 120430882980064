import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import "./register.css";
import "../../App.css";
import { AiFillEyeInvisible, AiFillEye, AiFillCloseCircle, AiFillCheckCircle } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import { useCreateUserWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { auth, db } from "../../services/firebaseConfig";
import { useLocation } from "react-router-dom";
import { query, where, getDocs, collection, doc, setDoc, getDoc, onSnapshot, updateDoc } from 'firebase/firestore';
import ProgressoLista from "../../components/C_Body/Items/saveProgressLista";


function RegisterLista() {

    const [showProgress, setShowProgress] = useState(false);
    const [showEmailAlert, setShowEmailAlert] = useState(false);

    const handleEnviarClick = async () => {
        const nome = document.getElementById("nome").value;
        const email = document.getElementById("email").value;
        const whats = document.getElementById("whats").value;
        const insta = document.getElementById("insta").value;

        if (!nome || !email || !whats || !insta) {
            // Se algum campo estiver vazio, mostrar alerta e não enviar
            setShowEmailAlert(true);
            return;
        }

        // Criar um documento na coleção 'listaEspera' com os dados fornecidos
        try {
            const listaEsperaRef = doc(db, "listaEspera", nome); // Use o e-mail como ID do documento
            await setDoc(listaEsperaRef, { nome, email, whats, insta });

            // Se tudo correr bem, mostrar progresso (pode ser uma página de sucesso)
            setShowProgress(true);
        } catch (error) {
            console.error("Erro ao enviar dados:", error);
        }
    };


    return (
        <>
       

        


        
        <div className="registerP">
        <div style={{position:'absolute', bottom:'8px', color:'rgba(255,255,255,0.3)', fontSize:'12px'}}>© 2023 Ktalus - Todos os direitos reservados</div>
{showProgress && <ProgressoLista  />}
            <div className="r--card">
                <div className='r--header'>
                    <img style={{ width: "155px"}}
                    src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2FKtalus%20Logo%20H.png?alt=media&token=6ded890b-555f-41b2-8bfb-fad7700339df&_gl=1*wr9qza*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NzY0MjI4Ny4yNTcuMS4xNjk3NjQyMzIwLjI3LjAuMA.."
                    alt="Ktalus"/>
                    <h1 className="h1sub">Pra entrar na Lista de Espera,<br/> preencha todos os campos abaixo </h1>
                 </div>
                

            <div className="r--form">
                
                <div className='form--input'>
                    <div className='label'>Nome do estúdio ou profissional</div>
                    <input  id="nome" type="text"  name="nome" autoComplete="off"
                        placeholder="Digite o nome aqui"  
                    />
                </div>

                <div className='form--input'>
                    <div className='label'>Email</div>
                    <input  id="email" type="email"  name="email" autoComplete="off"
                        placeholder="Digite seu melhor email"  
                    />
                </div>

                <div className='form--input'>
                    <div className='label'>WhatsApp(DDD+Celular)</div>
                    <input  id="whats" type="text" pattern="[0-9]*" name="whats" autoComplete="off"
                        placeholder="XX 9 XXXX XXXX"  
                    />
                </div>

                <div className='form--input'>
                    <div className='label'>Instagram</div>
                    <input  id="insta" type="text"  name="insta" autoComplete="off"
                        placeholder="Digite o nome aqui"  
                    />
                </div>

                    {showEmailAlert && <div className="alert">Todos os campos devem ser preenchidos</div>}
                     
                        <div className="TUPP">Ao clicar em 'Enviar' você aceita receber mensagens da Ktalus Ink via WhatsApp, Email ou Instagram </div>
                        <button type="button" className="login" onClick={handleEnviarClick}>
                            Enviar
                        </button>
                   
                </div>
            </div>
        </div>
    </>
    );
}

export default RegisterLista;
