const EnviarMensagemAPI = ({numero, nomeEstudio}) => {
    const enviarMensagem = async () => {
      const apiUrl = 'https://teste.uzapi.com.br:3333/sendText';
      const sessionKey = '123456';
      const requestBody = {
        session: 'Ktalus',
        number: numero,
        text: `Parabéns *${nomeEstudio}*, seja  bem vindo(a) a *Ktalus Ink*!⚜️\nAgora você faz parte dos profissionais que tem um catálogo de respeito.\n\nPara acessar a Plataforma e configurar o catálogo no seu estilo, use o link abaixo, com o email e senha que você cadastrou.\nhttps://ktalusink.pro/login\n\nNesta etapa inicial, preparamos um Manual com as coisas mais importantes que você precisa saber para já colocar seu catálogo online. Você poderá encontrá-lo na Plataforma ou diretamente neste link:https://bit.ly/KtalusInkManual\n\nE por último, mas não menos importante, salve este contato, pois é através dele que você terá acesso ao nosso Suporte, caso precise. Estaremos à disposição, de segunda a sexta (exceto feriados), das 9h às 12h e das 14h às 18h.\n\nAtenciosamente, _*Equipe Ktalus Ink⚜️*_  `,
      };
  
      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'SessionKey': sessionKey,
          },
          body: JSON.stringify(requestBody),
        });
  
        if (!response.ok) {
          throw new Error(`Erro na chamada API: ${response.statusText}`);
        }
  
        console.log('Mensagem enviada com sucesso!');
      } catch (error) {
        console.error('Erro ao enviar mensagem:', error);
      }
    };
  
    // Chama a função para enviar a mensagem ao montar o componente
    enviarMensagem();
  
    return null; // Retorna null para indicar que não há JSX a ser renderizado
  };
  
  export default EnviarMensagemAPI;