import './termoPolitica.css';

function Termo (){


    return(
        <div className="termopage">
            <div className='termo--cab'>
                <img className='logoK'
                src='https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2FKtalusBlack.svg?alt=media&token=17878113-58be-4e5f-9f0d-0f75aaf7ec44&_gl=1*1glkdr1*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NzgxMTQ0NC4yNzIuMS4xNjk3ODExNDU0LjUwLjAuMA..'
                alt=''/>
                <h1>Termos e condições de uso</h1>
            </div>

            <h2 >1. Termos</h2>
      <p >
        Ao acessar ao site{' '}
        <a href="https://ktalusink.pro">Plataforma Ktalus Ink</a>, concorda em cumprir estes termos de serviço, todas as
        leis e regulamentos aplicáveis e concorda que é responsável pelo cumprimento de todas as leis locais aplicáveis.
        Se você não concordar com algum desses termos, está proibido de usar ou acessar este site. Os materiais contidos
        neste site são protegidos pelas leis de direitos autorais e marcas comerciais aplicáveis.
      </p>
      <h2 >2. Uso de Licença</h2>
      <p >
        É concedida permissão para baixar temporariamente uma cópia dos materiais (informações ou software) no site
        Plataforma Ktalus Ink, apenas para visualização transitória pessoal e não comercial. Esta é a concessão de uma
        licença, não uma transferência de título e, sob esta licença, você não pode:
      </p>
      <ol>
        <li >modificar ou copiar os materiais;</li>
        <li >usar os materiais para qualquer finalidade comercial ou para exibição pública (comercial ou não comercial);</li>
        <li >tentar descompilar ou fazer engenharia reversa de qualquer software contido no site Plataforma Ktalus Ink;</li>
        <li >remover quaisquer direitos autorais ou outras notações de propriedade dos materiais; ou</li>
        <li >transferir os materiais para outra pessoa ou 'espelhe' os materiais em qualquer outro servidor.</li>
      </ol>
      <p >
        Esta licença será automaticamente rescindida se você violar alguma dessas restrições e poderá ser rescindida por
        Plataforma Ktalus Ink a qualquer momento. Ao encerrar a visualização desses materiais ou após o término desta
        licença, você deve apagar todos os materiais baixados em sua posse, seja em formato eletrónico ou impresso.
      </p>
      <h2 >3. Isenção de responsabilidade</h2>
      <ol>
        <li >
          Os materiais no site da Plataforma Ktalus Ink são fornecidos 'como estão'. Plataforma Ktalus Ink não oferece
          garantias, expressas ou implícitas, e, por este meio, isenta e nega todas as outras garantias, incluindo, sem
          limitação, garantias implícitas ou condições de comercialidade, adequação a um fim específico ou não violação
          de propriedade intelectual ou outra violação de direitos.
        </li>
        <li >
          Além disso, o Plataforma Ktalus Ink não garante ou faz qualquer representação relativa à precisão, aos
          resultados prováveis ou à confiabilidade do uso dos materiais em seu site ou de outra forma relacionado a
          esses materiais ou em sites vinculados a este site.
        </li>
      </ol>
      <h2 >4. Limitações</h2>
      <p >
        Em nenhum caso o Plataforma Ktalus Ink ou seus fornecedores serão responsáveis por quaisquer danos (incluindo,
        sem limitação, danos por perda de dados ou lucro ou devido a interrupção dos negócios) decorrentes do uso ou da
        incapacidade de usar os materiais em Plataforma Ktalus Ink, mesmo que Plataforma Ktalus Ink ou um representante
        autorizado da Plataforma Ktalus Ink tenha sido notificado oralmente ou por escrito da possibilidade de tais
        danos. Como algumas jurisdições não permitem limitações em garantias implícitas, ou limitações de
        responsabilidade por danos consequentes ou incidentais, essas limitações podem não se aplicar a você.
      </p>
      <h2 >5. Precisão dos materiais</h2>
      <p >
        Os materiais exibidos no site da Plataforma Ktalus Ink podem incluir erros técnicos, tipográficos ou
        fotográficos. Plataforma Ktalus Ink não garante que qualquer material em seu site seja preciso, completo ou
        atual. Plataforma Ktalus Ink pode fazer alterações nos materiais contidos em seu site a qualquer momento, sem
        aviso prévio. No entanto, Plataforma Ktalus Ink não se compromete a atualizar os materiais.
      </p>
      <h2 >6. Links</h2>
      <p >
        O Plataforma Ktalus Ink não analisou todos os sites vinculados ao seu site e não é responsável pelo conteúdo de
        nenhum site vinculado. A inclusão de qualquer link não implica endosso por Plataforma Ktalus Ink do site. O uso
        de qualquer site vinculado é por conta e risco do usuário.
      </p>
      <br />
      <h2 >7. Inatividade de Conta</h2>
      <p >
      O Plataforma Ktalus Ink reserva o direito de excluir seções, tatuagens e arquivos associados a uma conta de usuário que permanecer inativa por um período contínuo de 90 dias. A inatividade é definida como a ausência de pagamento da assinatura na plataforma durante esse período. Antes da exclusão, o Plataforma Ktalus Ink pode enviar notificações de lembrete para o endereço de e-mail registrado na conta. A exclusão de dados após o período de inatividade não gera responsabilidade por parte do Plataforma Ktalus Ink.
      </p>
      <br />
      <h3 >Modificações</h3>
      <p >
        O Plataforma Ktalus Ink pode revisar estes termos de serviço do site a qualquer momento, sem aviso prévio. Ao
        usar este site, você concorda em ficar vinculado à versão atual desses termos de serviço.
      </p>
      <h3 >Lei aplicável</h3>
      <p >
        Estes termos e condições são regidos e interpretados de acordo com as leis do Plataforma Ktalus Ink e você se
        submete irrevogavelmente à jurisdição exclusiva dos tribunais naquele estado ou localidade.
      </p>


        </div>
    )
}


export default Termo;