import React, { useEffect, useState, useRef } from 'react';
import HeaderPage from "../../components/C_Body/HeaderPage";
import { updateDoc, getDocs, query, where, getFirestore, collection, doc, } from "firebase/firestore";
import { getStorage, ref, deleteObject } from "firebase/storage";

import { db } from '../../services/firebaseConfig';
import { BsTrash } from "react-icons/bs";
import { BiEdit, BiSolidImageAdd } from "react-icons/bi";
import CropEasy1x1Perfil from './crop/CropEasy1x1Perfil';


function P03Perfil ({ user, estudio, perfil, nome, whats, nick, setComponenteAtivo }){

    const [editNome, setEditNome] = useState(false);
    const [editWhats, setEditWhats] = useState(false);
    const [editLink, setEditLink] = useState(false);

    const [nomeState, setNomeState] = useState(nome);
  const [whatsState, setWhatsState] = useState(whats);
  const [nickState, setNickState] = useState(nick);

  const [disponibilidade, setDisponibilidade] = useState("");


  const handleNomeChange = (e) => {
    let valor = e.target.value;
    if (valor.length <= 25) {
        valor = valor.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
        setNomeState(valor); // Atualiza o estado nomeState com o novo valor formatado
    }
};

// Função para lidar com mudanças no campo de WhatsApp
const handleCelularChange = (e) => {
    let valor = e.target.value.replace(/\D/g, ''); // Remove caracteres não numéricos

    if (valor.length >= 2) { // Impede a remoção dos dois primeiros dígitos
        if (valor.length <= 13) { // Limita o número de caracteres a 13
            setWhatsState(valor);
        }
    }
};

const handleNickChange = (e) => {
    let valor = e.target.value;
    valor = valor.replace(/[^a-zA-Z0-9_]/g, ''); // Remove caracteres especiais, exceto underline

    if (valor.length <= 20) { // Limita o número de caracteres a 20
        setNickState(valor);
    }
};




  useEffect(() => {
    setNomeState(nome);
    setWhatsState(whats);
    setNickState(nick);
}, [nome, whats, nick]);

    const atualizarCampoFirestore = async () => {
        try {
            const estudioRef = doc(db, "estudio", estudio); // Referência para o documento
            await updateDoc(estudioRef, {
                nomeEstudio: nomeState,
            });
            
            console.log("Documento atualizado com sucesso!");
            
            // Seta o estado 'editNome' como false após atualizar o documento com sucesso
            setEditNome(false);
        } catch (error) {
            console.error("Erro atualizando documento: ", error);
        }
    };

    const atualizarWhatsappFirestore = async () => {
        try {
            const estudioRef = doc(db, "estudio", estudio);
            const whatsSemEspacos = whatsState.replace(/\s/g, ''); // Adiciona o prefixo 55 e remove todos os espaços
            await updateDoc(estudioRef, {
                whatsapp: whatsSemEspacos,
            });
            
            console.log("WhatsApp atualizado com sucesso!");
            
            // Esconde o campo de edição após o sucesso
            setEditWhats(false);
        } catch (error) {
            console.error("Erro atualizando WhatsApp: ", error);
        }
    };

    const atualizarNicknameFirestore = async () => {
        try {
            const estudioRef = doc(db, "estudio", estudio);
            await updateDoc(estudioRef, {
                nickname: nickState,
            });
            
            console.log("Nickname atualizado com sucesso!");
            
            // Esconde o campo de edição após o sucesso
            setEditLink(false);
        } catch (error) {
            console.error("Erro atualizando nickname: ", error);
        }
    };
    
    

    useEffect(() => {
        if (nickState.length >= 4) {
          const timer = setTimeout(async () => {
            if (nickState === nick) {
              setDisponibilidade("-");
            } else {
              setDisponibilidade("verificando");
              const q = query(collection(db, "estudio"), where("nickname", "==", nickState));
              const querySnapshot = await getDocs(q);
              if (querySnapshot.empty) {
                setDisponibilidade("disponível");
              } else {
                setDisponibilidade("indisponível");
              }
            }
          }, 1000);
          return () => clearTimeout(timer);
        }
    }, [nickState, nick, db]);
    
    // Função para remover a imagem e atualizar o campo 'perfil'
const removerFoto = async () => {
    if (perfil) {
        // Criar uma referência para o arquivo no armazenamento do Firebase
        const storage = getStorage();
        const fotoRef = ref(storage, `estudios/${user}.jpg`); // Substitua pelo caminho correto

        // Excluir o arquivo
        try {
            await deleteObject(fotoRef);

            // Agora, atualize o campo 'perfil' para vazio na base de dados
            const estudioRef = doc(db, "estudio", estudio);
            await updateDoc(estudioRef, {
                perfil: "",
            });

            console.log("Foto e campo de perfil atualizados com sucesso");

        } catch (error) {
            console.error("Erro ao excluir foto: ", error);
        }
    }
};

const [globalCroppedPhotoURL, setGlobalCroppedPhotoURL] = useState(null);
const [selectedFile, setSelectedFile] = useState(null);
const [openCrop, setOpenCrop] = useState(false);
const [croppedPhotoURL, setCroppedPhotoURL] = useState(globalCroppedPhotoURL);

const fileInputRef = useRef(null);

useEffect(() => {
  setGlobalCroppedPhotoURL(croppedPhotoURL);
}, [croppedPhotoURL, setGlobalCroppedPhotoURL]);

const handleClickUpload = () => {
  fileInputRef.current.click();
};

const handleFileChange = (e) => {
    const file = e.target.files[0];
    const maxFileSize = 20 * 1024 * 1024; // 10 MB em bytes
  
    if (file) {
      // Verifique o tamanho do arquivo
      if (file.size <= maxFileSize) {
        setSelectedFile(file);
        setOpenCrop(true);
      } else {
        alert('O arquivo é muito grande! Por favor, selecione um arquivo de no máximo 10 MB.');
      }
    }
  };

const handleRemove = () => {
  setSelectedFile(null); 
  setCroppedPhotoURL(null); 
  setOpenCrop(false); 
  if (fileInputRef.current) {
    fileInputRef.current.value = null;
  }
};



    

    return(
        <div className='PerfilPage'>
            <HeaderPage pg={'Perfil'} setComponenteAtivo={setComponenteAtivo} />
            <div className='perfilPage--container'>
                <div className='perfilPage--fotoContainer'>

                <input
                    type="file"
                    id="fotoperfil"
                    accept=".jpg, .jpeg, .png, .webp" 
                    ref={fileInputRef} 
                    onChange={handleFileChange} 
                    
                    style={{ display: 'none' }}
                   
                />
                { perfil ? (
                    <div>
                        <img className='fotoContainer--foto'
                            src={perfil ? perfil : "https://seu-link-de-imagem-padrao.com/imagem.jpg"}  
                            alt='' />
                        <button className='fotoContainer--remover' 
                                onClick={removerFoto} 
                                style={{ display: perfil ? "flex" : "none" }}>
                        <BsTrash />Remover
                        </button>
                    </div>
                    ) : (
                    <div className='pr--uploadPerfil' onClick={handleClickUpload}>
                        <BiSolidImageAdd style={{fontSize:'64px'}} />
                        Carregar foto<br/>de perfil
                    </div>
                    )}

                    {openCrop && (
                            <div className={openCrop ? "crop-popup" : "crop-popup-hidden"}>
                            <CropEasy1x1Perfil 
                            photoURL={URL.createObjectURL(selectedFile)} 
                            setOpenCrop={setOpenCrop} 
                            setCroppedPhotoURL={setCroppedPhotoURL} 
                            handleRemove={handleRemove}
                            estudio={estudio}
                            />
                            </div>
                            )}



                
                </div>

                <div className='perfilPage--nomeContainer'>
                    Nome
                    {!editNome && <div className='nomeContainer--nomePreview'>
                        <div className='nomeContainer--nome'>{nomeState}</div>
                        <div className='nomeContainer--buttonEdit' onClick={() =>setEditNome(true)}><BiEdit/></div>
                    </div>}

                    {editNome && <div className='nomeContainer--nomeEdit'>
                        <div className='nomeEditInput'>
                        <input 
                    type='text'  
                    name='nomeEdit'
                    autocomplete="off"
                    value={nomeState}
                    onChange={handleNomeChange}
                /> 
                        <div className='contadorNome'><span className='caracteres'>{nomeState.length}</span>/25</div>
                        </div>
                        <div className='buttonsEdit'>
                            <div className='cancelButtonEdit' onClick={() =>setEditNome(false)}>Cancelar</div>
                            <div className='saveButtonEdit' onClick={atualizarCampoFirestore}>Salvar</div>
                        </div>
                        
                    </div>}

                </div>

                <div className='perfilPage--WhatsContainer'>
                    WhatsApp
                    {!editWhats && <div className='nomeContainer--nomePreview'>
                        <div className='nomeContainer--nome'>{whats.substring(2)}</div>
                        <div className='nomeContainer--buttonEdit'onClick={() =>setEditWhats(true)}><BiEdit/></div>
                    </div>}

                    {editWhats && <div className='nomeContainer--whatsEdit'>
                        <div className='nomeEditInput'>
                        <input 
                            type="text"
                            name="whatsEdit"
                            autocomplete="off"
                            value={whatsState}
                            onChange={handleCelularChange}
                            pattern="[0-9]*"
                            inputMode="numeric"
                        />

                        </div>
                        <div className='buttonsEdit'>
                            <div className='cancelButtonEdit'onClick={() =>setEditWhats(false)}>Cancelar</div>
                            <div className='saveButtonEdit' onClick={atualizarWhatsappFirestore}>Salvar</div>
                        </div>
                        
                    </div>}

                </div>

                <div className='perfilPage--linkContainer'>
                    Endereço de link
                    {!editLink && <div className='nomeContainer--nomePreview'>
                        <div className='nomeContainer--link'>ktalusink.pro/{nick}</div>
                        <div className='nomeContainer--buttonEdit'onClick={() =>setEditLink(true)}><BiEdit/></div>
                    </div>}

                    {editLink && <div className='nomeContainer--linkEdit'>
    <div className='nomeEditInput'>
        <input 
            type='text'  
            autocomplete="off"
            name='linkEdit'
            value={nickState}
            onChange={handleNickChange} 
        />
        <div className='contadorNome'>
            <span className='caracteres'>{disponibilidade}</span>
        </div>
        
    </div>
    <div className='buttonsEdit'>
        <div className='cancelButtonEdit' onClick={() => setEditLink(false)}>Cancelar</div>
        <div className='saveButtonEdit' onClick={atualizarNicknameFirestore}>Salvar</div>
    </div>
</div>}


                </div>



            </div>
        </div>
    )
}


export default P03Perfil;



