import { BsLink45Deg, BsQrCode, BsInfinity, BsFillKeyFill } from "react-icons/bs";
import { HiColorSwatch } from "react-icons/hi";
import { FaRankingStar } from "react-icons/fa6";
import { BiSupport } from "react-icons/bi";


function AssTestMensal({setComponenteAtivo}){

    function abrirLink() {
        window.open('https://pay.kiwify.com.br/dRsQoTd', '_blank');
      }
      


    return(
        <div className="ass-modoMensal" >
        <div className="ass--modo">
            <div style={{fontWeight:700, fontSize:'24px'}}>Mensal</div>

            <div className="toggleM" onClick={() => setComponenteAtivo("anual")}>
                <div className="toggleM--ballM"></div>
            </div>

            <div onClick={() => setComponenteAtivo("anual")} style={{display:'flex', flexDirection:'row', alignItems:'center', gap:'4px', color:'#FEEC6E'}}>Anual<span style={{fontSize:'12px', color:'#94FF94'}}>-20%</span></div>
        
        </div>

        <div className="ass--itens">
                <div className="ass--item"><BsLink45Deg style={{marginLeft:'64px', fontSize:'20px', color:'#FEEC6E'}}/>Link exclusivo</div>
                <div className="ass--item"><BsQrCode style={{marginLeft:'64px', fontSize:'20px', color:'#FEEC6E'}}/>QR-Code</div>
                <div className="ass--item"><HiColorSwatch style={{marginLeft:'64px', fontSize:'20px', color:'#FEEC6E'}}/>Catálogo personalizado</div>
                <div className="ass--item"><FaRankingStar style={{marginLeft:'64px', fontSize:'20px', color:'#FEEC6E'}}/>Seção TOP7</div>
                <div className="ass--item"><BsInfinity style={{marginLeft:'64px', fontSize:'20px', color:'#FEEC6E'}}/>Tattos ilimitadas</div>
                <div className="ass--item"><BiSupport style={{marginLeft:'64px', fontSize:'20px', color:'#FEEC6E'}}/>Suporte exclusivo</div>
                <div className="ass--item" style={{fontWeight:700}}><BsFillKeyFill style={{marginLeft:'64px', fontSize:'20px', color:'#FEEC6E'}}/>Acesso por 30 dias</div>

        </div>

        <div className="ass--preco">
            <div style={{fontSize:'29px'}}>R$</div> 
            <div style={{fontSize:'124px', lineHeight:0.85, fontWeight:700, position:'relative' }}>99
            <div className="ass--mes" >/mês</div></div> 
            <div style={{fontSize:'29px'}}>,90</div>   
        </div>

        <div style={{width:'100%', display:'flex', justifyContent:'center'}}>no Pix ou Cartão de Crédito</div>

        <div className="ass--botaoCTA" onClick={abrirLink}>Quero o Plano Mensal</div>
        <div style={{fontSize:'12px', color:'#94FF94'}}> Compra segura pela Kiwify</div>

    </div>
    )
    
}


export default AssTestMensal;