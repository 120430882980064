import React, { useState, useEffect } from "react";

import {  AiOutlineClose } from "react-icons/ai";

import AssOfertaMensal from "./P06AssOfertaMensal";
import AssOfertaAnual from "./P06AssOfertaAnual";

function AssOferta({ setMostrarOferta, plano, email }){
    const [componenteAtivo, setComponenteAtivo] = useState("mensal");

    

    return(
        <div className="bgAcao">
            <div className="cardAss">
                
                <img className='AssCantoA'
                    src='https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fglifo%20ktalus%202.svg?alt=media&token=efb9dc0a-e0e0-4eb2-98ed-41056c4e2c0b&_gl=1*i7hoic*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NzE0MDYwNi4yMzYuMS4xNjk3MTQwNzc1LjI3LjAuMA..'
                    alt=''/>
                <img className='AssCantoD'
                    src='https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fglifo%20ktalus%202.svg?alt=media&token=efb9dc0a-e0e0-4eb2-98ed-41056c4e2c0b&_gl=1*i7hoic*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NzE0MDYwNi4yMzYuMS4xNjk3MTQwNzc1LjI3LjAuMA..'
                    alt=''/>

<div className="closePlan" onClick={() => setMostrarOferta(false)}><AiOutlineClose/></div>

               {componenteAtivo === 'mensal' && <AssOfertaMensal plano={plano} setComponenteAtivo={setComponenteAtivo} email={email}/>}
               {componenteAtivo === 'anual' && <AssOfertaAnual plano={plano} setComponenteAtivo={setComponenteAtivo} email={email} />}




                

               

            </div>
        </div>
    )
}


export default AssOferta;