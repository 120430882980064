import Header from "../../components/Header";
import React, { useState, useEffect} from "react";
import { BsQrCode } from "react-icons/bs";
import { AiFillDatabase} from "react-icons/ai";
import { BiSolidUserPin, BiSupport } from "react-icons/bi";
import { BsPhoneFill, BsBookHalf } from "react-icons/bs";
import { HiColorSwatch } from "react-icons/hi";
import { FaMoneyCheckDollar, FaGift } from "react-icons/fa6";
import { LuFileStack } from "react-icons/lu";
import { ImExit } from "react-icons/im";
import Suporte from "./P07Suporte";
import { useNavigate } from 'react-router-dom';
import firebase from 'firebase/app';
import { getAuth, signOut } from "firebase/auth";
import { CSSTransition, TransitionGroup } from 'react-transition-group'; 



import 'firebase/auth'; 
import AssOferta from "./P06AssOferta";





function Plataforma( { nome, perfil, setComponenteAtivo, createdTime, modoTest, val } ){
    console.log(createdTime)
    const [mostrarSuporte, setMOstrarSuporte] = useState(false);
    const navigate = useNavigate();
    const [opacity, setOpacity] = useState(1);
    const [mostrarOferta, setMostrarOferta] = useState(false);
    const [oferta, setOferta] = useState(false);

    const calculateDaysLeft = () => {
        const now = new Date().getTime();
    
        // Adicione esta verificação
        const valTimestamp = val instanceof Date ? val.getTime() : new Date(val).getTime();
        
        const difference = valTimestamp - now;
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        
        return days;
    };
    
    
      const [daysLeft, setDaysLeft] = useState(calculateDaysLeft());

      useEffect(() => {
        if (createdTime && createdTime.seconds) {
            const timestampAtual = new Date().getTime() / 1000;
            const createdTimestamp = createdTime.seconds;
            const novoTimestamp = createdTimestamp + (48 * 3600);
          
            if (timestampAtual <= novoTimestamp) {
                setOferta(true);
            }
        }
    }, [createdTime]);
    

   
    const handleLogout = () => {
        const auth = getAuth();
        signOut(auth)
        .then(() => {
            navigate('/login');
        })
        .catch((error) => {
            console.error("Erro ao deslogar: ", error);
        });
    };

    const handleBorderClick = (value) => {
        setOpacity(0);
        setTimeout(() => {
            setComponenteAtivo(value);
        }, 400);
    };
    
    function formataDataV(dataTimestamp) {
        if (!dataTimestamp || !dataTimestamp.seconds) {
            // Retorne uma mensagem de erro ou um valor padrão
            return "Data não disponível";
        }
    
        const meses = [
            "Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"
        ];
    
        // Converte o timestamp para milissegundos
        const data = new Date(dataTimestamp.seconds * 1000);
    
        // Adiciona dois dias à data
        data.setDate(data.getDate() + 2);
    
        // Obtém o dia, mês e ano
        const dia = data.getDate();
        const mes = meses[data.getMonth()];
        const ano = data.getFullYear();
    
        // Retorna a data formatada
        return `${dia} ${mes} ${ano}`;
    }
    
   
      
      const dataValFormatada = formataDataV(createdTime);

      const mostrarOferta1 = oferta && modoTest;

      function abrirLink() {
        window.open('https://ktalusink.notion.site/Manual-Ktalus-Ink-97f4687e1f4d4ac3a01a45a54400fa04?pvs=4', '_blank');
      }

    return(
        <>
            
            <div className="plataforma-bg"  style={{opacity: opacity}}>
            <Header nome={nome} perfil={perfil} />
            <div style={{position:'absolute', bottom:'8px', color:'rgba(255,255,255,0.3)', fontSize:'12px'}}>© 2023 Ktalus - Todos os direitos reservados</div>

           {modoTest && <div className="regressivo">
           {daysLeft > 0 ? (
    <div>Seu período de teste expira em <span style={{fontWeight: 700}}>{`${daysLeft} dias`}</span></div>
) : daysLeft === 0 ? (
    <div>Seu período de teste expira <span style={{fontWeight: 700}}>HOJE</span></div>
) : (
    <div>Seu período de teste expirou</div>
)}
            </div>}
             
            {mostrarOferta1 && 
                    <div className="oferta">
                        <div className="oferta--tx1">
                            <div className="oferta--tx">
                                <FaGift style={{ fontSize: '32px' }} />
                                Tem uma oferta <br /> disponível para você
                            </div>

                            <div className="oferta--val">Válido até {dataValFormatada}</div>
                        </div>

                        <div className="oferta--bt" onClick={()=>setMostrarOferta(true)}>Conferir</div>
                    
                    {mostrarOferta && <AssOferta setMostrarOferta={setMostrarOferta}/>}
                    </div>

                }

            <div className='plataforma' >


                <div id="plat01" className="plataforma-border" onClick={() => handleBorderClick("link")}>
                     {/*<img className="plataforma-cantoBotaoA"
                    src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fglifo%20ktalus.png?alt=media&token=6f3f33c2-b9e1-4df0-9b3b-e247d8f63421&_gl=1*1hc7r3b*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjUzNzc2Mi4yMTIuMS4xNjk2NTM3OTQyLjYwLjAuMA.." alt=""/>
                    <img className="plataforma-cantoBotaoD"
                    src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fglifo%20ktalus.png?alt=media&token=6f3f33c2-b9e1-4df0-9b3b-e247d8f63421&_gl=1*1hc7r3b*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjUzNzc2Mi4yMTIuMS4xNjk2NTM3OTQyLjYwLjAuMA.." alt=""/>*/}
                    
                    <div className="plataforma-botao">
                        <BsQrCode style={{fontSize:'32px'}}/>
                        Link / QR Code
                    </div>
                </div>

                <div id="plat02" className="plataforma-border" onClick={() => handleBorderClick("catalogo")}>
                    {/*<img className="plataforma-cantoBotaoA"
                    src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fglifo%20ktalus.png?alt=media&token=6f3f33c2-b9e1-4df0-9b3b-e247d8f63421&_gl=1*1hc7r3b*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjUzNzc2Mi4yMTIuMS4xNjk2NTM3OTQyLjYwLjAuMA.." alt=""/>
                    <img className="plataforma-cantoBotaoD"
                    src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fglifo%20ktalus.png?alt=media&token=6f3f33c2-b9e1-4df0-9b3b-e247d8f63421&_gl=1*1hc7r3b*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjUzNzc2Mi4yMTIuMS4xNjk2NTM3OTQyLjYwLjAuMA.." alt=""/>*/}
                    <div className="plataforma-botao">
                    <AiFillDatabase style={{fontSize:'32px'}}/>
                        Catálogo
                    </div>
                </div>

                <div id="plat03" className="plataforma-border" onClick={() => handleBorderClick("perfil")}>
                    {/*<img className="plataforma-cantoBotaoA"
                    src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fglifo%20ktalus.png?alt=media&token=6f3f33c2-b9e1-4df0-9b3b-e247d8f63421&_gl=1*1hc7r3b*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjUzNzc2Mi4yMTIuMS4xNjk2NTM3OTQyLjYwLjAuMA.." alt=""/>
                    <img className="plataforma-cantoBotaoD"
                    src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fglifo%20ktalus.png?alt=media&token=6f3f33c2-b9e1-4df0-9b3b-e247d8f63421&_gl=1*1hc7r3b*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjUzNzc2Mi4yMTIuMS4xNjk2NTM3OTQyLjYwLjAuMA.." alt=""/>*/}
    
                    <div className="plataforma-botao">
                    <BiSolidUserPin style={{fontSize:'32px'}}/>
                        Perfil
                    </div>
                </div>

                <div id="plat04" className="plataforma-border" onClick={() => handleBorderClick("pgInicial")}>
                    {/*<img className="plataforma-cantoBotaoA"
                    src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fglifo%20ktalus.png?alt=media&token=6f3f33c2-b9e1-4df0-9b3b-e247d8f63421&_gl=1*1hc7r3b*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjUzNzc2Mi4yMTIuMS4xNjk2NTM3OTQyLjYwLjAuMA.." alt=""/>
                    <img className="plataforma-cantoBotaoD"
                    src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fglifo%20ktalus.png?alt=media&token=6f3f33c2-b9e1-4df0-9b3b-e247d8f63421&_gl=1*1hc7r3b*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjUzNzc2Mi4yMTIuMS4xNjk2NTM3OTQyLjYwLjAuMA.." alt=""/>*/} 
                    <div className="plataforma-botao">
                    <BsPhoneFill style={{fontSize:'32px'}}/>
                        Página Inicial
                    </div>
                </div>

                <div id="plat05" className="plataforma-border" onClick={() => handleBorderClick("personalizar")}>
                    {/*<img className="plataforma-cantoBotaoA"
                    src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fglifo%20ktalus.png?alt=media&token=6f3f33c2-b9e1-4df0-9b3b-e247d8f63421&_gl=1*1hc7r3b*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjUzNzc2Mi4yMTIuMS4xNjk2NTM3OTQyLjYwLjAuMA.." alt=""/>
                    <img className="plataforma-cantoBotaoD"
                    src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fglifo%20ktalus.png?alt=media&token=6f3f33c2-b9e1-4df0-9b3b-e247d8f63421&_gl=1*1hc7r3b*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjUzNzc2Mi4yMTIuMS4xNjk2NTM3OTQyLjYwLjAuMA.." alt=""/>*/}
                    <div className="plataforma-botao">
                    <HiColorSwatch style={{fontSize:'32px'}}/>
                        Personalizar
                    </div>
                </div>

                <div id="plat06" className="plataforma-border" onClick={() => handleBorderClick("assinatura")}>
                    {/*<img className="plataforma-cantoBotaoA"
                    src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fglifo%20ktalus.png?alt=media&token=6f3f33c2-b9e1-4df0-9b3b-e247d8f63421&_gl=1*1hc7r3b*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjUzNzc2Mi4yMTIuMS4xNjk2NTM3OTQyLjYwLjAuMA.." alt=""/>
                    <img className="plataforma-cantoBotaoD"
                    src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fglifo%20ktalus.png?alt=media&token=6f3f33c2-b9e1-4df0-9b3b-e247d8f63421&_gl=1*1hc7r3b*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjUzNzc2Mi4yMTIuMS4xNjk2NTM3OTQyLjYwLjAuMA.." alt=""/>*/} 
                    <div className="plataforma-botao">
                    <FaMoneyCheckDollar style={{fontSize:'32px'}}/>
                        Assinatura
                    </div>
                </div>

                <div id="plat07" className="plataforma-border" onClick={abrirLink}>
                    {/*<img className="plataforma-cantoBotaoA"
                    src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fglifo%20ktalus.png?alt=media&token=6f3f33c2-b9e1-4df0-9b3b-e247d8f63421&_gl=1*1hc7r3b*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjUzNzc2Mi4yMTIuMS4xNjk2NTM3OTQyLjYwLjAuMA.." alt=""/>
                    <img className="plataforma-cantoBotaoD"
                    src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fglifo%20ktalus.png?alt=media&token=6f3f33c2-b9e1-4df0-9b3b-e247d8f63421&_gl=1*1hc7r3b*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjUzNzc2Mi4yMTIuMS4xNjk2NTM3OTQyLjYwLjAuMA.." alt=""/>*/}
                    <div className="plataforma-botao">
                    <BsBookHalf style={{fontSize:'32px'}}/>
                        Manual
                    </div>
                </div>

                
                {/*   <div id="plat08" className="plataforma-borderSDI" onClick={() => handleBorderClick("SDI")}>
                    <img className="plataforma-cantoBotaoA"
                    src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fglifo%20ktalus.png?alt=media&token=6f3f33c2-b9e1-4df0-9b3b-e247d8f63421&_gl=1*1hc7r3b*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjUzNzc2Mi4yMTIuMS4xNjk2NTM3OTQyLjYwLjAuMA.." alt=""/>
                    <img className="plataforma-cantoBotaoD"
                    src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fglifo%20ktalus.png?alt=media&token=6f3f33c2-b9e1-4df0-9b3b-e247d8f63421&_gl=1*1hc7r3b*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjUzNzc2Mi4yMTIuMS4xNjk2NTM3OTQyLjYwLjAuMA.." alt=""/>
                    
                    <img style={{height:"80%"}}
                        src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2FGroup%20466.png?alt=media&token=78fad67f-d034-460e-9c22-4e931e0971cb"
                        alt=""/>
                    <div className="plataforma-botao">
                    <LuFileStack style={{fontSize:'32px'}}/>
                        Roteiro SDI
                       
                    </div>
                
                </div>*/}
                
                

                <div id="plat08" className="plataforma-border" onClick={() => setMOstrarSuporte(true)}>
                    {/*<img className="plataforma-cantoBotaoA"
                    src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fglifo%20ktalus.png?alt=media&token=6f3f33c2-b9e1-4df0-9b3b-e247d8f63421&_gl=1*1hc7r3b*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjUzNzc2Mi4yMTIuMS4xNjk2NTM3OTQyLjYwLjAuMA.." alt=""/>
                    <img className="plataforma-cantoBotaoD"
                    src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fglifo%20ktalus.png?alt=media&token=6f3f33c2-b9e1-4df0-9b3b-e247d8f63421&_gl=1*1hc7r3b*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjUzNzc2Mi4yMTIuMS4xNjk2NTM3OTQyLjYwLjAuMA.." alt=""/>*/}
                    <div className="plataforma-botao">
                    <BiSupport style={{fontSize:'32px'}}/>
                        Suporte
                    </div>
                </div>

               
                    <div id="plat09" className="plataforma-botaoExit" onClick={handleLogout}>
                    <ImExit style={{fontSize:'32px'}}/>
                        Sair
                    </div>
                
            </div> 
            {mostrarSuporte && <Suporte onClose={()=>setMOstrarSuporte(false)}/>}
            </div>

        
        </>
    )
}


export default Plataforma;