import { Cancel, CheckCircle } from '@mui/icons-material';
import CropIcon from '@mui/icons-material/Crop';
import { Box, Button, DialogActions, DialogContent, Slider, Typography } from '@mui/material';
import React, { useState } from "react";
import Cropper from 'react-easy-crop';
import "../../../components/C_Body/Items/items.css";
import getCroppedImg from './utils/cropImage';
import { getFirestore, collection, updateDoc, doc } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { getAuth } from "firebase/auth";

const CropEasy1x1Perfil = ({ photoURL, setOpenCrop, setCroppedPhotoURL, setAlert, setLoading, handleRemove, imageFile, estudio }) => {
    
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [rotation, setRotation] = useState(0);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [croppedImageBlob, setCroppedImageBlob] = useState(null);

    const cropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }

    const cropAndSetImage = async () => {
        try {
            const { file, url } = await getCroppedImg(photoURL, croppedAreaPixels, rotation);
            
            // Salva o blob da imagem cortada no estado
            setCroppedImageBlob(file);  
    
            // Atualiza a URL cortada no estado
            setCroppedPhotoURL(url);
            
            // Fecha o componente de corte
            setOpenCrop(false);
    
            // Faz o upload da imagem cortada
            await handleSave(file);
    
        } catch (error) {
            setAlert({ isAlert: true, severity: "error", message: error.message, timeout: 5000, location: "modal" })
            console.log(error)
        }
    }

  


    const handleSave = async (imageBlob) => {
        const db = getFirestore();
        const storage = getStorage();
        const auth = getAuth();
        const user = auth.currentUser;
        const storageRef = ref(storage, `estudios/${user.uid}.jpg`);
        
        const uploadTask = uploadBytesResumable(storageRef, imageBlob);
    
        uploadTask.on('state_changed', 
          (snapshot) => {},
          (error) => {},
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
              const estudioRef = doc(db, 'estudio', estudio);
              await updateDoc(estudioRef, {
                perfil: downloadURL
              });
              console.log("Campo 'perfil' atualizado com sucesso.");
            });
          }
        );
    };

      const clearSelectedInput = () => {
        
        handleRemove();
        setOpenCrop(false);
      };

    return (
        <div className='comp' style={{background:"#333"}}>
            <div>
                <DialogContent sx={{
                    background: "#fff",
                    position: "relative",
                    height: 400,
                    width: 400,
                    margin: "auto"
                }}>
                    <Cropper
                        image={photoURL}
                        crop={crop}
                        zoom={zoom}
                        rotation={rotation}
                        aspect={1}
                        onZoomChange={setZoom}
                        onRotationChange={setRotation}
                        onCropChange={setCrop}
                        onCropComplete={cropComplete}
                    />
                </DialogContent>
            </div>

            <div>
            <DialogActions sx={{ display: "flex", flexDirection: "row", mx: 3, my: 2,  }}>
                    <Box sx={{ display: "flex",width:'100%', flexDirection: "column", gap: 2, margin: "auto" }}>
                       
                       <Box sx={{ display: "flex", flexDirection: "row", gap:'16px', margin: "auto", width:'100%', color:'#fff'  }}>
                        <Box sx={{ width:'100%', mb:1}}>
                            <Box>
                            <Typography>Zoom:</Typography>
                            <Slider
                            valueLabelDisplay='auto'
                            valueLabelFormat={zoomPercent}
                            min={1} max={3}
                            step={0.1}
                            value={zoom}
                            onChange={(e,zoom)=> setZoom(zoom)}

                            />
                            </Box>
                        </Box>

                        <Box sx={{width:'100%', mb:1}}>
                            <Box>
                            <Typography>Rotacionar:</Typography>
                            <Slider
                            valueLabelDisplay='auto'
                            
                            min={0} max={360}
                            step={1}
                            value={rotation}
                            onChange={(e,rotation)=> setRotation(rotation)}

                            />
                            </Box>
                        </Box>

                        </Box >
                        <Box sx={{ display: "flex", flexDirection: "row", gap: 2, margin: "auto", width:'90%',}}>
                        <Button sx={{  color: "#FEEC6E", border: "1px solid #FEEC6E" }}
                            variant='outlined'
                            startIcon={<Cancel />}
                            onClick={clearSelectedInput}
                        >
                            Cancelar
                        </Button>
                        <Button variant='contained' startIcon={<CheckCircle />}  onClick={() => { cropAndSetImage(); handleSave(estudio);  }} 
                        sx={{ background: "#FEEC6E", color:'#000' }}>
                            Salvar
                        </Button>
                        </Box>
                    </Box>
                </DialogActions>
            </div>
        </div>
    )
}

export default CropEasy1x1Perfil;

const zoomPercent = (value)=>{
    return `${Math.round(value * 100)}%`
}