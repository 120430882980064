import './items.css';
import { useEffect, useRef } from 'react';
import { MdDeleteForever } from "react-icons/md";
import { getDocs, query,  where, collection, doc, deleteDoc, updateDoc, getDoc } from "firebase/firestore";
import { getStorage, ref, deleteObject } from "firebase/storage";
import { db } from '../../../services/firebaseConfig';

function DeleteTattoo({ttID, nome, handleCancelar, eID, scID, img1, img2, img3, img4, setIsSettingsVisible }) {
  const delSecaoRef = useRef(null);
  

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (delSecaoRef.current && !delSecaoRef.current.contains(event.target)) {
        handleCancelar(); // Fecha o componente quando clicado fora dele
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleCancelar]);

   // Função para deletar um arquivo no Firebase Storage
   const deleteFile = async (url) => {
    const storage = getStorage();
    const filePath = url.split('o/')[1].split('?')[0];
    const decodedFilePath = decodeURIComponent(filePath);
    const fileRef = ref(storage, decodedFilePath);

    try {
      await deleteObject(fileRef);
      
    } catch (error) {
      console.error("Erro ao deletar o arquivo:", error);
    }
  };

// Função para decrementar o campo 'ordem' de tatuagens na mesma seção
const decrementOrderForTattoos = async (currentOrder, scID) => {
  const tattoosRef = collection(db, "tattoos");
  const q = query(tattoosRef, where("SecaoID", "==", scID), where("ordem", ">", currentOrder));

  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((doc) => {
    const docRef = doc.ref;
    updateDoc(docRef, {
      ordem: doc.data().ordem - 1
    });
  });
};


  // Função para deletar o documento na Firestore e as imagens no Storage
  const handleDeletar = async () => {
    // Primeiro, obtenha o 'ordem' atual da tatuagem que está sendo deletada
    const tattooDoc = doc(db, "tattoos", ttID);
    const tattooData = await getDoc(tattooDoc);
    const currentOrder = tattooData.data().ordem;
  
    // Decrementa o campo 'ordem' para tatuagens com 'ordem' maior que a atual
    await decrementOrderForTattoos(currentOrder, scID);
  
    // Deleta as imagens, se existirem
    if (img1) await deleteFile(img1);
    if (img2) await deleteFile(img2);
    if (img3) await deleteFile(img3);
    if (img4) await deleteFile(img4);
  
    // Deleta o documento na Firestore
    try {
      await deleteDoc(tattooDoc);
      setIsSettingsVisible(false); // Supondo que você queira esconder a janela de configurações
      handleCancelar();
    } catch (error) {
      console.error('Erro ao deletar o documento:', error);
    }
  };
  
  

  return (
    <div className="bgAcao">
      <div className="cardAddSecaoDel" ref={delSecaoRef}>
        <div className="delAction">
          <MdDeleteForever className='iconSet' />
          Deletar Tattoo
        </div>

        <div className="contCardDel">
          Tem certeza que deseja deletar a tattoo <span className="nomeSDel">{nome}?</span>

          <div className="botoesCardDel">
            <button className="brCardCancelDel" onClick={handleCancelar}>
              Não, cancelar
            </button>
            <button className="btCardConfirmDel" onClick={handleDeletar}>
              Sim, deletar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteTattoo;
